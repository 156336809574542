import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";
import SearchIcon from "../../../../../assets/icons/SearchIcon.svg";
import DropDownIcon from "../../../../../assets/icons/DropDownIcon.svg";
import SortIcon from "../../../../../assets/icons/SortIcon.svg";
import FilterIcon from "../../../../../assets/icons/FilterIcon.svg";

import AthletesListCard from "../../../../../components/afterLogin/brands/home/subComponents/athletes/athletes.list.card";

import { dashboardAthletesDetails } from "../../../../../_mock/dummyData";
import Pulse from "../../../../../themes/@overrides/pulse";
import { useEffect } from "react";
import { brandGetAthleteList } from "../../../../../services/brand/brand.service";
import { sportList } from "../../../../../services/admin/admin.service";
import { dataInterestList } from "../../../../../services/data/data.service";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  Sort,
  SwapVert,
} from "@mui/icons-material";
import { useDebounce } from "use-debounce";

const AthletesList = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const params = new URLSearchParams(window.location.search);
  const clientSecretString = params.get("page");
  const clientSecret = parseInt(clientSecretString);

  const [searchTerm, setSearchTerm] = useState("");
  const [apiData, setApiData] = useState({
    token: localStorage.getItem("token"),
    // searchBy: "",
    search: searchTerm,
    filter: "",
    sortBy: "",
    sortOrder: "",
    page: clientSecret ? clientSecret : 1,
    size: 12,
  });
  const [debouncedSearchTerm] = useDebounce(apiData?.search, 3000);

  const [athletesData, setAthletesData] = useState(null);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [interestOptions, setInterestOptions] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [filterBy, setFilterBy] = useState({
    gender: "",
    sports: [],
    interests: [],
  });
  const [filterCount, setFilterCount] = useState({
    gender: 0,
    sports: 0,
    interests: 0,
  });
  const [sportsMenuOpen, setSportsMenuOpen] = useState(false);
  const [interestMenuOpen, setInterestMenuOpen] = useState(false);
  const [sortDialogOpen, setSortDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const value = [1, 2, 3];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [sortBy, setSortBy] = useState(null);
  const sortByOptionsList = [
    {
      // id: 1,
      key: "1",
      value: "Name a-z",
    },
    {
      // id: 2,
      key: "-1",
      value: "Name z-a",
    },
  ];

  const handleSortByClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/dashboard/athletes?page=${apiData?.page}`
    );

    callApi();
  }, [(apiData?.sortBy && apiData?.sortOrder) || apiData?.page]);

  const handleSortByClick = (menuItem) => {
    setSortBy((preItem) => (preItem?.key === menuItem?.key ? "" : menuItem));
    setApiData((prevApiData) => ({
      ...prevApiData,
      page: 1,
      sortBy: prevApiData.sortOrder === menuItem.key ? "" : "name",
      sortOrder: prevApiData.sortOrder === menuItem.key ? "" : menuItem.key,
    }));
    handleSortByClose();
  };

  const handleSortBy = (e) => {
    setAnchorEl(e.target);
  };

  const handleSportsMenuClose = () => {
    setSportsMenuOpen(false);
  };

  const handleInterestsMenuClose = () => {
    setInterestMenuOpen(false);
  };

  const handlePrev = () => {
    if (apiData?.page > 1) {
      setApiData((prevApiData) => ({
        ...prevApiData,
        page: prevApiData.page - 1,
      }));

      callApi();
    }
  };

  const handleNext = () => {
    setApiData((prevApiData) => ({
      ...prevApiData,
      page: prevApiData.page + 1,
    }));

    callApi();
  };

  useEffect(() => {
    callApi();
    fetchSportsOptions();
    fetchInterestOptions();
  }, []);

  const callApi = async () => {
    setLoading(true);
    const response = await brandGetAthleteList(apiData);
    setLoading(false);

    if (response.status === 200) {
      setAthletesData([...response.data]);
      // setDataStatus(true);
      setFilteredData([...response.data]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      console.error("Failed to fetch athletes:", response.message);
    }
  };

  //const athletesData = location.state?.athletesData || [];

  const handleBack = () => {
    navigate(-1);
  };

  const fetchSportsOptions = async () => {
    try {
      const response = await sportList(token);

      if (response?.status === 200) {
        setSportsOptions(response?.data);
      } else {
        console.error("Failed to fetch sports options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching sports options", error);
    }
  };

  const fetchInterestOptions = async () => {
    try {
      const response = await dataInterestList(token);

      if (response?.status === 200) {
        setInterestOptions(response.data);
      } else {
        console.error("Failed to fetch interest options:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching interest options", error);
    }
  };

  const totalFilterSelected =
    filterCount?.gender + filterCount?.sports + filterCount?.interests;

  const handleFilters = () => {
    setFilterDialogOpen(true);
  };

  const handleFilterDialogClose = () => {
    setFilterDialogOpen(false);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    if (name === "sports" || name === "interests") {
      if (value.includes("")) {
        setFilterBy((preData) => ({ ...preData, [name]: [] }));
        setFilterCount((preCount) => ({ ...preCount, [name]: 0 }));
      } else {
        setFilterBy((preData) => ({ ...preData, [name]: value }));
        setFilterCount((preCount) => ({ ...preCount, [name]: 1 }));
      }
    } else {
      setFilterBy((preData) => ({ ...preData, [name]: value }));
      setFilterCount((preCount) => ({
        ...preCount,
        [name]: value !== "" ? 1 : 0,
      }));
    }
  };

  const handleApply = async () => {
    let append = [];
    if (filterBy?.gender) {
      append.push(`gender:${filterBy.gender}`);
    }

    if (filterBy?.sports?.length > 0) {
      filterBy?.sports?.forEach((sport) => {
        append.push(`sports:${sport}`);
      });
    }

    if (filterBy?.interests?.length > 0) {
      filterBy?.interests?.forEach((interest) => {
        append.push(`interest:${interest}`);
      });
    }
    setApiData((prevApiData) => ({
      ...prevApiData,
      page: 1,
      filter: append.join(","),
    }));

    try {
      handleFilterDialogClose();
      setLoading(true);
      const response = await brandGetAthleteList({
        ...apiData,
        page: 1,
        filter: append.join(","),
      });
      setLoading(false);

      if (response.status === 200) {
        setAthletesData([...response.data]);
      } else {
        console.error("Failed to fetch athletes:", response.message);
      }
    } catch (error) {
      console.error("Error fetching athletes", error);
    }
  };

  const handleSearch = (e) => {
    setLoading(true);
    setSearchTerm(e.target.value);
  };

  const searchedAthletes = athletesData?.filter((athlete) => {
    const lowerCaseName = athlete?.name?.toLowerCase();
    const lowerCaseSports = athlete?.sports?.toLowerCase();
    const lowerCaseSchool = athlete?.school?.toLowerCase();

    return (
      lowerCaseName?.includes(searchTerm.toLowerCase()) ||
      lowerCaseSports?.includes(searchTerm.toLowerCase()) ||
      lowerCaseSchool?.includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    if (searchTerm !== "") {
      setApiData((prevFormData) => ({
        ...prevFormData,
        page: 1,
        search: searchTerm,
      }));
    }
  }, [searchTerm]);

  useEffect(() => {
    callApi();
  }, [debouncedSearchTerm]);

  return (
    <Box
      sx={{
        paddingX: "30px",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="115px"
        mb="10px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Box
            component="img"
            src={LeftArrow}
            alt="Left Arrow"
            width="25px"
            sx={{ cursor: "pointer" }}
            onClick={handleBack}
          ></Box>
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            sx={{ fontSize: { xs: "30px", md: theme.fonts.heading } }}
          >
            Athletes
          </Typography>
        </Box>
      </Box>

      <Box
        mt="30px"
        p="20px"
        overflow="hidden"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 3,
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            m: 3,
          }}
        >
          <Box
            backgroundColor="green"
            sx={{ width: { xs: "100%", sm: "50%", md: "70%" } }}
          >
            <TextField
              name="search"
              placeholder="Search by athlete"
              fullWidth
              autoComplete="off"
              size="small"
              value={searchTerm}
              onChange={handleSearch}
              // onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search Icon" />
                  </InputAdornment>
                ),
                sx: {
                  border: `1px solid ${theme.colors.text.greyLightDark}`,
                  background: theme.colors.common.black,
                  color: theme.colors.common.white,
                  borderRadius: "5px",
                },
              }}
              InputLabelProps={{
                sx: {
                  color: theme.colors.text.greyLight,
                },
              }}
              sx={{
                // width: { xs: "300px", md: "100%" },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      borderColor: theme.colors.common.white,
                    },
                  },
                },
              }}
            />
          </Box>
          <Button
            id="sort-button"
            onClick={handleSortBy}
            aria-controls={open ? "sortBy-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            sx={{
              textTransform: "none",
              border: `1px solid ${theme.colors.text.greyLightDark}`,
              borderRadius: "5px",
              p: "5px 20px",
              height: "42px",
              width: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              fontWeight: 600,
              fontSize: { xs: "18px", md: "20px" },
              color: theme.colors.text.greyLightDark,
              background: theme.colors.common.black,
            }}
            endIcon={<SwapVert />}
          >
            Sort
            {/* <Box
              component="img"
              src={SortIcon}
              alt="dropdownIcon"
              sx={{ cursor: "pointer" }}
            ></Box> */}
          </Button>
          <Menu
            id="sortBy-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleSortByClose}
            MenuListProps={{ "aria-labelledby": "sort-button" }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PopoverClasses={{
              paper: "custom-paper-class",
            }}
            sx={{
              "& .custom-paper-class": {
                minWidth: "200px",
                maxWidth: "220px",
                backgroundColor: "black",
                color: "white",
                filter: "drop-shadow(0px 4px 4px rgba(255,255,255,0.3))",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
                px: "10px",
                py: "5px",
              }}
            >
              <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                Sort
              </Typography>
              <SwapVert />
            </Box>
            <Divider sx={{ background: theme.colors.text.greyDark }} />
            <Box sx={{ pt: "10px", px: "10px" }}>
              {sortByOptionsList?.map((menuItem, index) => (
                <MenuItem
                  onClick={() => handleSortByClick(menuItem)}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "rgba(39,39,39,1)",
                    color: theme.colors.text.greyLight,
                    borderRadius: "5px",
                    mt: index === 0 ? 0 : 0.8,
                    px: "8px",
                    py: "3px",
                    fontSize: "14px",
                    fontFamily: theme.fonts.livvic,
                    "&:hover": {
                      background: "rgba(18,18,18,1)",
                      color: "white",
                    },
                  }}
                >
                  <Typography>{menuItem?.value}</Typography>
                  {sortBy?.key === menuItem?.key ? (
                    <RadioButtonChecked sx={{ color: "red" }} />
                  ) : (
                    <RadioButtonUnchecked />
                  )}
                </MenuItem>
              ))}
            </Box>
          </Menu>
          <Box
            sx={{
              border: `1px solid ${theme.colors.text.greyLightDark}`,
              borderRadius: "5px",
              p: "5px 20px",
              height: "42px",
              width: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              color: theme.colors.text.greyLightDark,
              background: theme.colors.common.black,
            }}
            onClick={handleFilters}
          >
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "20px" },
                fontWeight: 600,
                fontFamily: theme.fonts.livvic,
              }}
            >
              Filters
            </Typography>
            <Box
              component="img"
              src={FilterIcon}
              alt="dropdownIcon"
              sx={{ cursor: "pointer" }}
            ></Box>
            <Box sx={{ position: "relative" }}>
              {totalFilterSelected > 0 && (
                <Box
                  sx={{
                    position: "absolute",
                    top: -14,
                    right: -1,
                    width: "14px",
                    height: "14px",
                    borderRadius: "50%",
                    background: "red",
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "10px",
                  }}
                >
                  {totalFilterSelected}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {loading ? (
          <Box display="flex" flexWrap="wrap" width="90%">
            {value?.map((index) => (
              <Box
                key={index}
                ml="20px"
                mr="30px"
                sx={{ minWidth: { xs: "100%", sm: "270px" } }}
              >
                <Skeleton
                  sx={{
                    bgcolor: "grey.900",
                    borderRadius: "4px",
                    width: "100%",
                    marginTop: "10px",
                  }}
                  variant="rectangular"
                  height={250}
                />
                <Skeleton
                  sx={{
                    width: "100%",
                    bgcolor: "grey.900",
                    borderRadius: "4px",
                    marginTop: "10px",
                  }}
                  variant="rectangular"
                  height={20}
                />
                <Skeleton
                  sx={{
                    width: "100%",
                    bgcolor: "grey.900",
                    borderRadius: "4px",
                    marginTop: "10px",
                  }}
                  variant="rectangular"
                  height={20}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <>
            {searchedAthletes && searchedAthletes?.length > 0 ? (
              searchedAthletes?.map((detail, index) => (
                <AthletesListCard
                  detail={detail}
                  page={apiData?.page}
                  key={index}
                />
              ))
            ) : (
              <Typography
                py="100px"
                fontFamily={theme.fonts.livvic}
                fontWeight={theme.fonts.fontWeightMedium}
                sx={{ fontSize: "20px" }}
              >
                No athletes found
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box
        sx={{
          gap: "30px",
          mb: "30px",
          pb: "30px",
          px: "80px",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Button
          onClick={handlePrev}
          disabled={apiData?.page === 1}
          sx={{
            px: { xs: "50px", md: "70px" },
            textTransform: "none",
            color: "white",
            fontSize: { xs: "18px", md: "20px" },
            borderRadius: "5px",
            backgroundColor: "#DD0000",
            marginTop: "10px",
            fontWeight: 500,
            "&.MuiButton-root.Mui-disabled": {
              background: "#252525",
              color: "#c5c5c5",
            },
            "&:hover": {
              backgroundColor: "red",
            },
          }}
        >
          Previous
        </Button>
        <Typography sx={{ mt: "5px", fontSize: "20px", fontWeight: 600 }}>
          {apiData?.page}
        </Typography>
        <Button
          onClick={handleNext}
          disabled={searchedAthletes?.length < 12}
          sx={{
            px: { xs: "50px", md: "80px" },
            textTransform: "none",
            color: "white",
            fontSize: { xs: "18px", md: "20px" },
            borderRadius: "5px",
            backgroundColor: "#DD0000",
            marginTop: "10px",
            fontWeight: 500,
            "&.MuiButton-root.Mui-disabled": {
              background: "#252525",
              color: "#c5c5c5",
            },
            "&:hover": {
              backgroundColor: "red",
            },
          }}
        >
          Next
        </Button>
      </Box>
      <Dialog
        open={filterDialogOpen}
        onClose={handleFilterDialogClose}
        PaperProps={{
          sx: {
            minWidth: "50vw",
            background: "black",
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontSize={theme.fonts.sectionSubHeading}
            >
              Gender
            </Typography>
            <Select
              name="gender"
              value={filterBy?.gender}
              onChange={handleFilterChange}
              sx={{
                border: `1px solid ${theme.colors.text.greyDarker}`,
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",
                height: "45px",
                width: "100%",
                "& .MuiSelect-icon": {
                  color: "white",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    background: theme.colors.background.formInput,
                    color: theme.colors.text.greyLight,
                  },
                },
              }}
            >
              <MenuItem value="">No Selection</MenuItem>
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Non-Binary">Non-Binary</MenuItem>
            </Select>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontSize={theme.fonts.sectionSubHeading}
            >
              Sports
            </Typography>
            <Select
              name="sports"
              value={filterBy?.sports}
              onChange={(event) => {
                handleFilterChange(event);
                handleSportsMenuClose();
              }}
              open={sportsMenuOpen}
              onOpen={() => setSportsMenuOpen(true)}
              multiple
              sx={{
                border: `1px solid ${theme.colors.text.greyDarker}`,
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",
                height: "45px",
                width: "100%",
                "& .MuiSelect-icon": {
                  color: "white",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    background: theme.colors.background.formInput,
                    color: theme.colors.text.greyLight,
                  },
                },
                onClose: handleSportsMenuClose,
              }}
            >
              <MenuItem value="">No Selection</MenuItem>
              {sportsOptions?.map((sport, index) => (
                <MenuItem key={index} value={sport}>
                  {sport}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontSize={theme.fonts.sectionSubHeading}
            >
              Interests
            </Typography>
            <Select
              name="interests"
              value={filterBy?.interests}
              onChange={(event) => {
                handleFilterChange(event);
                handleInterestsMenuClose();
              }}
              open={interestMenuOpen}
              onOpen={() => setInterestMenuOpen(true)}
              multiple
              sx={{
                border: `1px solid ${theme.colors.text.greyDarker}`,
                outline: "none",
                borderRadius: "10px",
                backgroundColor: "black",
                color: "white",
                height: "45px",
                width: "100%",
                "& .MuiSelect-icon": {
                  color: "white",
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    background: theme.colors.background.formInput,
                    color: theme.colors.text.greyLight,
                  },
                },
                onClose: handleInterestsMenuClose,
              }}
            >
              <MenuItem value="">No Selection</MenuItem>
              {interestOptions?.map((interest, index) => (
                <MenuItem key={index} value={interest}>
                  {interest}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            onClick={handleApply}
            sx={{
              width: "150px",
              height: "45px",
              background: "#FF0101",
              color: "white",
              "&:hover": {
                backgroundColor: "darkred",
              },
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightBold,
              textTransform: "none",
              fontSize: { xs: "16px", md: "20px" },
            }}
          >
            Apply
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AthletesList;

import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HOST_URL, PADDING_X } from "../../utils/constants";

import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from "swiper/react";
import Pulse from "../../themes/@overrides/pulse";
import ExploreBrandsCard from "../../components/beforeLogin/home/subComponents/exploreBrandsCard";
import { useNavigate } from "react-router-dom";
import {
  brandSubscriptionPlans,
  getFeaturedBrands,
} from "../../services/brand/brand.service";
import FontsLoader from "../fontsLoader";
import BurrstLogo from "../../assets/logo/BurrstLogo.svg";
import { Navigation } from "swiper/modules";

const EXPLORE_BRAND_LIST = [
  {
    id: 1,
    logo: BurrstLogo,
    name: "Burrst",
    about:
      "Immerse yourself in quality, innovation, and style, elevating your sporting experience with the latest trends in sports.",
  },
  {
    id: 2,
    logo: BurrstLogo,
    name: "Burrst",
    about:
      "Immerse yourself in quality, innovation, and style, elevating your sporting experience with the latest trends in sports.",
  },
  {
    id: 3,
    logo: BurrstLogo,
    name: "Burrst",
    about:
      "Immerse yourself in quality, innovation, and style, elevating your sporting experience with the latest trends in sports.",
  },
  {
    id: 4,
    logo: BurrstLogo,
    name: "Burrst",
    about:
      "Immerse yourself in quality, innovation, and style, elevating your sporting experience with the latest trends in sports.",
  },
];

const AthletesDescription = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [formData, setFormData] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [subscriptionPlanLoading, setSubscriptionPlanLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleHome = () => {
    if (token) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  const handleRegister = () => {
    navigate("/brands/register");
  };

  const handleContactSales = () => {
    window.location.href = "mailto:grant@burrst.io";
  };

  const fetchSubscriptionPlans = async () => {
    const response = await brandSubscriptionPlans();
    setSubscriptionPlanLoading(false);

    if (response.status === 200) {
      setFormData([...response.data]);
    } else {
      console.error(response?.message);
    }
  };

  const fetchBrands = async () => {
    const response = await getFeaturedBrands();
    setIsLoading(false);
    if (response?.status === 200) {
      setBrandsList(response?.data?.data);
    } else {
      console.error(response?.message);
    }
  };

  useEffect(() => {
    fetchSubscriptionPlans();
    fetchBrands();
  }, []);

  return (
    <>
      <Helmet>
        <title>Brands Description | Burrst</title>
        <meta
          name="description"
          content="Showcase your brand with customizable profiles featuring
                  images and video highlights."
        />
        <link rel="canonical" href={`${HOST_URL}/brands`} />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* -------------------Heading-------------------------- */}
        <Box
          sx={{
            mt: { xs: "55px", sm: "65px", md: "75px", lg: "85px", xl: "100px" },
            mb: "30px",
            px: { xs: "10px", sm: PADDING_X },
            height: "150px",
            width: "100%",
            maxWidth: "1536px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background:
              " linear-gradient(50deg, rgba(246,0,0,0.2962962962962963) 0%, rgba(0,0,0,1) 24%, rgba(0,0,0,1) 76%, rgba(246,0,0,0.30092592592592593) 100%)",
          }}
        >
          <Box>
            <Typography
              fontFamily={theme.fonts.russoOne}
              fontSize={{ xs: theme.fonts.heading_xs, sm: theme.fonts.heading }}
            >
              Brands
            </Typography>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Typography
                onClick={handleHome}
                sx={{ cursor: "pointer" }}
                fontFamily={theme.fonts.livvic}
              >
                Home
              </Typography>
              {"|"}
              <Typography fontFamily={theme.fonts.livvic} color="red">
                Brands
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* --------------------Subscription Details------------------- */}
        {subscriptionPlanLoading ? (
          <FontsLoader />
        ) : (
          <Box
            sx={{
              px: { xs: "10px", sm: PADDING_X },
              width: "100%",
              maxWidth: "1536px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                mb: 2,
                mt: 4,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Pulse />
                <Typography
                  sx={{
                    fontFamily: theme.fonts.russoOne,
                    fontSize: {
                      xs: theme.fonts.heading_xs,
                      sm: theme.fonts.heading,
                    },
                    fontWeight: "bold",
                    color: theme.colors.text.greyDark,
                  }}
                >
                  Pick Your
                  <span style={{ color: "red", marginLeft: 8 }}>Gameplan</span>
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: { xs: "16px", sm: "18px" },
                  color: "white",
                  ml: 4,
                }}
              >
                *Note- $30/month for every additional license user
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 5,
              }}
            >
              {formData?.map((plan, index) => (
                <Box
                  key={index}
                  sx={{
                    border: plan?.details?.isRecommended
                      ? "1px solid red"
                      : "none",
                    position: "relative",
                    width: "300px",
                  }}
                >
                  {plan?.details?.isRecommended && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "18px",
                        background: "rgba(256,0,0,0.7)",
                        textAlign: "center",
                        fontSize: "12px",
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      RECOMMENDED
                    </Box>
                  )}
                  <Box
                    sx={{
                      px: "10px",
                      pt: "20px",
                      pb: "10px",
                      background: "rgba(39,39,39,1)",
                      height: "130px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {plan?.planName}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: theme.fonts.livvic,
                        fontSize: "14px",
                        color: theme.colors.text.greyLight,
                      }}
                    >
                      {plan?.details?.planDescription}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      px: "10px",
                      py: "10px",
                      background: "rgba(56,56,56,1)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1.5,
                        minHeight: {
                          xs: "auto",
                          sm: plan?.details?.isRecommended ? "232px" : "234px",
                        },
                      }}
                    >
                      {plan?.details?.planBenefits?.map(
                        (benefits, benefitsIndex) => (
                          <Box
                            key={benefitsIndex}
                            sx={{ display: "flex", gap: 0.5 }}
                          >
                            <Box
                              component="img"
                              src="/BurrstSmallLogo.svg"
                              alt="BurrstSmallLogo"
                              height="20px"
                            ></Box>
                            <Typography
                              sx={{
                                fontFamily: theme.fonts.livvic,
                                lineHeight: 1.2,
                              }}
                            >
                              {benefits}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>

                    <Typography
                      sx={{
                        height: "20px",
                        textAlign: "center",
                        fontFamily: theme.fonts.livvic,
                        mt: 1,
                        mb: 2,
                      }}
                    >
                      {plan?.details?.showButton
                        ? `$${plan?.amount}/month`
                        : ""}
                    </Typography>

                    <Box sx={{ textAlign: "center", my: 1 }}>
                      <Button
                        variant={
                          plan?.details?.isRecommended
                            ? "contained"
                            : "outlined"
                        }
                        fullWidth
                        sx={{
                          color: "white",
                          fontFamily: "livvic",
                          textTransform: "none",
                          background: plan?.details?.isRecommended
                            ? "rgba(255, 0, 0, 1)"
                            : "transparent",
                          border: plan?.details?.isRecommended
                            ? "none"
                            : "1px solid rgba(255, 0, 0, 1)",
                          borderRadius: "10px",
                          "&:hover": {
                            background: "rgba(255, 0, 0, 0.5)",
                            border: plan?.details?.isRecommended
                              ? "none"
                              : "1px solid rgba(255, 0, 0, 1)",
                          },
                        }}
                        onClick={
                          plan?.details?.showButton
                            ? handleRegister
                            : handleContactSales
                        }
                      >
                        {plan?.details?.showButton
                          ? `Get Started`
                          : "Contact Sales"}
                      </Button>
                    </Box>

                    <Typography
                      sx={{
                        height: { sm: "40px" },
                        fontFamily: theme.fonts.livvic,
                        mb: 1,
                      }}
                    >
                      {plan?.details?.showButton
                        ? `30-day free trial of ${plan?.planName} features`
                        : ""}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        )}
        {/* ----------------------Brand Benefits----------------------- */}
        {/* <Box
          sx={{
            background:
              "linear-gradient(90deg, rgba(250,0,0,0.05) 4%, rgba(250,0,0,0.05) 16%, rgba(0,0,0,1) 30%)",
            px: { xs: "10px", sm: PADDING_X },
            width: "100%",
            maxWidth: "1536px",
          }}
        > */}
        {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              pt: "20px",
              mb: "20px",
            }}
          >
            <Pulse />
            <Typography
              sx={{
                fontFamily: theme.fonts.russoOne,
                // fontWeight: "bold",
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                  xl: theme.fonts.beforeLogin.heading.xl,
                },
                color: theme.colors.text.greyDark,
              }}
            >
              Brand<span style={{ color: "red", marginLeft: 8 }}>Benefits</span>
            </Typography>
          </Box> */}

        {/* ----------------Content and Images------------------------- */}
        {/* <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
              gap: 2,
              pb: "30px",
            }}
          > */}
        {/* ---------------Image Swiper----------------------- */}
        {/* <Box
              sx={{
                width: { xs: "100%", md: "50%", xl: "40%" },
              }}
            >
              <Swiper
                pagination={true}
                grabCursor={true}
                centeredSlides={true}
                effect={"coverflow"}
                slidesPerView={3}
                // loop={true}
                autoplay={{
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                initialSlide={1}
                modules={[Pagination, EffectCoverflow, Autoplay]}
                coverflowEffect={{
                  // rotate: 40,
                  stretch: 0,
                  depth: 100,
                  slideShadows: true,
                }}
                breakpoints={{
                  0: { slidesPerView: 1 },
                  350: { slidesPerView: 2 },
                  500: { slidesPerView: 3 },
                  900: { slidesPerView: 2 },
                  1050: { slidesPerView: 3 },
                }}
                style={{
                  "--swiper-pagination-color": "red",
                  "--swiper-pagination-bullet-inactive-color": "#fff",
                  "--swiper-pagination-bullet-inactive-opacity": "1",
                  "--swiper-pagination-bullet-horizontal-gap": "6px",
                  "--swiper-pagination-bottom": "0px",
                  height: "350px",
                  width: "100%",
                }}
              >
                {BRAND_BENEFITS_IMAGE_LIST?.map((detail, index) => (
                  <SwiperSlide key={index} style={{ height: "90%" }}>
                    <Box
                      sx={{
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Box
                        component="img"
                        src={detail?.imgUrl}
                        alt={detail?.alt}
                        sx={{
                          height: "100%",
                        }}
                      ></Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box> */}

        {/* ----------------Brand Benefits Content------------------ */}
        {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: { xs: "100%", md: "50%", xl: "60%" },
              }}
            >
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Unlock the potential of 3,600+ student-athletes.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Measure and understand the returns on your campaigns with our
                  ROI NIL analytics.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Discover the perfect student-athletes for your brand with AI
                  recommendations.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    Keep tabs on your NIL deals with our cutting-edge tracking
                    system.
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  <span
                    style={{
                      color: "white",
                      // fontWeight: "bold"
                    }}
                  >
                    Embrace transparency and security with smart contract
                    agreements.
                  </span>{" "}
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Seamlessly collaborate with athletes through our intuitive
                  platform.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Showcase your brand with customizable profiles featuring
                  images and video highlights.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Let AI supercharge your campaigns and engage athletes like
                  never before.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Connect and interact with athletes through our chat feature.
                </Typography>
              </Box>
              <Box sx={brandBenefitsContainerStyle}>
                <Box
                  component="img"
                  src="/BurrstSmallLogo.svg"
                  alt="BurrstSmallLogo"
                  height="25px"
                ></Box>

                <Typography sx={athleteBenefitsContentStyle}>
                  Stay in the know with weekly reports delivered straight to
                  your inbox.
                </Typography>
              </Box>
            </Box> */}
        {/* </Box>
        </Box> */}

        {/* --------------------Explore Brands-------------------------- */}
        <Box
          sx={{
            px: { xs: "10px", sm: PADDING_X },
            mb: "30px",
            width: "100%",
            maxWidth: "1536px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              my: "30px",
            }}
          >
            <Pulse />
            <Typography
              sx={{
                fontFamily: theme.fonts.russoOne,
                fontSize: {
                  xs: theme.fonts.subHeading_xs,
                  sm: theme.fonts.subHeading,
                  xl: theme.fonts.beforeLogin.heading.xl,
                },
                color: theme.colors.text.greyDark,
              }}
            >
              Explore<span style={{ color: "red", marginLeft: 8 }}>Brands</span>
            </Typography>
          </Box>

          <Swiper
            grabCursor={true}
            navigation={true}
            modules={[Navigation]}
            initialSlide={0}
            spaceBetween={20}
            breakpoints={{
              0: { slidesPerView: 1 },
              400: { slidesPerView: 1.5 },
              500: { slidesPerView: 1.8 },
              600: { slidesPerView: 2 },
              700: { slidesPerView: 2.5 },
              900: { slidesPerView: 2.5 },
              1000: { slidesPerView: 2.8 },
              1200: { slidesPerView: 3.5 },
              1400: { slidesPerView: 4 },
              1500: { slidesPerView: 4.5 },
              1600: { slidesPerView: 5 },
            }}
            style={{ padding: "0 20px" }}
          >
            {isLoading
              ? Array.from({ length: 5 }, (_, index) => (
                  <SwiperSlide key={index}>
                    <ExploreBrandsCard index={index} isLoading={isLoading} />
                  </SwiperSlide>
                ))
              : brandsList?.length
              ? brandsList?.map((detail, index) => (
                  <SwiperSlide key={index}>
                    <ExploreBrandsCard detail={detail} index={index} />
                  </SwiperSlide>
                ))
              : EXPLORE_BRAND_LIST?.map((detail, index) => (
                  <SwiperSlide key={index}>
                    <ExploreBrandsCard detail={detail} index={index} />
                  </SwiperSlide>
                ))}
          </Swiper>
        </Box>
      </Box>
    </>
  );
};

export default AthletesDescription;

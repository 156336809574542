import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { groupBy } from "lodash";
import { Grid, Typography, Box, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SignedAthleteCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/signedathlete.card";
import UnsignedathleteCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/unsignedathlete.card";
import { selectedAthlete } from "../../../../../services/campaign/campaign.service";

const CampaignSelectedAthlete = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [signedCount, setSignedCount] = useState(0);
  const [unsignedCount, setUnsignedCount] = useState(0);
  const [signedAthletes, setSignedAthletes] = useState([]);
  const [selectedAthleteData, setSelectedAthleteData] = useState([]);
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const sortedSignedAthletes = selectedAthleteData?.signed?.sort(
      (a, b) => new Date(b.selectedDate) - new Date(a.selectedDate)
    );

    const groupedSignedAthletes = groupBy(sortedSignedAthletes, "signedDate");

    setSignedAthletes(groupedSignedAthletes);
  }, [selectedAthleteData]);

  useEffect(() => {
    setSignedCount(selectedAthleteData?.signed?.length);
    setUnsignedCount(selectedAthleteData?.unsigned?.length);
  }, [selectedAthleteData]);

  const callApi = async () => {
    try {
      const response = await selectedAthlete();

      if (response.status === 200) {
        setSelectedAthleteData(response.data);
      } else {
        console.error(
          "Failed to fetch selected athlete details:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching selected athlete details:", error);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      <Grid my="20px" display="flex">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: "40px",
              color: "white",
              "@media (max-width:468px)": {
                fontSize: "25px",
              },
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          variant="h4"
          ml="15px"
          sx={{
            "@media (max-width:468px)": {
              fontSize: "22px",
            },
          }}
        >
          Selected Athletes
        </Typography>
      </Grid>
      <Grid
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        backgroundColor="#121212"
        padding="2%"
        mb="30px"
        gap="30px"
      >
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: theme.colors.common.black,
            border: "1px solid #AFACAC",
            width: { xs: "95%", md: "45%" },
            paddingY: { xs: "1.5%", md: "1%" },
          }}
          gap={2}
        >
          <Box>
            <Typography
              padding="8px"
              fontFamily={theme.fonts.russoOne}
              fontWeight={theme.fonts.fontWeightRegular}
              sx={{
                color: "#FF0101",
                // fontSize: "48px",
                fontSize: { xs: "20px", md: "34px" },
                borderRadius: "100%",
                padding: "10px",
                border: "1px solid #AFACAC",
              }}
            >
              {String(signedCount ?? 0).padStart(2, "0")}
            </Typography>
          </Box>
          <Box>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              sx={{ fontSize: { xs: "18px", md: "28px" } }}
            >
              Signed Athletes
            </Typography>
          </Box>
        </Grid>
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: theme.colors.common.black,
            border: "1px solid #AFACAC",
            width: { xs: "95%", md: "50%" },
            paddingY: { xs: "1.5%", md: "1%" },
          }}
          gap={2}
        >
          <Box>
            <Typography
              padding="8px"
              fontFamily={theme.fonts.russoOne}
              fontWeight={theme.fonts.fontWeightRegular}
              sx={{
                color: "#FF0101",
                fontSize: { xs: "20px", md: "34px" },
                borderRadius: "100%",
                padding: "10px",
                border: "1px solid #AFACAC",
              }}
            >
              {String(unsignedCount ?? 0).padStart(2, "0")}
            </Typography>
          </Box>
          <Box>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightBold}
              sx={{ fontSize: { xs: "18px", md: "28px" } }}
            >
              Unsigned Athletes
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid backgroundColor="#121212" padding="1%">
        <Grid>
          <Typography
            mt="20px"
            color={theme.colors.common.white}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            sx={{ fontSize: { xs: "18px", md: "24px" }, paddingX: "30px" }}
          >
            Signed Athletes
          </Typography>
          {selectedAthleteData?.signed?.length > 0 ? (
            <Grid>
              {Object.keys(signedAthletes)
                .sort((a, b) => new Date(b) - new Date(a))
                ?.map((date) => (
                  <Grid
                    key={date}
                    container
                    display="flex"
                    flexDirection="column"
                    //justifyContent="space-around"
                  >
                    <Box
                      display="flex"
                      justifyContent="right"
                      sx={{ paddingRight: { xs: "20px", md: "30px" } }}
                    >
                      <Typography
                        fontFamily={theme.fonts.livvic}
                        color="#918E8E"
                        sx={{
                          fontSize: { xs: "14px", md: "18px" },
                          fontWeight: 500,
                        }}
                      >
                        {new Date(date).toLocaleDateString("en-US", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                        })}{" "}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      spacing={4}
                      p="10px"
                      justifyContent="center"
                    >
                      {signedAthletes[date]?.map((detail, index) => (
                        <Grid
                          item
                          key={index}
                          xs={12}
                          sm={10}
                          md={9}
                          lg={6}
                          xl={4}
                        >
                          <SignedAthleteCard detail={detail} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ))}{" "}
            </Grid>
          ) : (
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily={theme.fonts.livvic}
              fontWeight={500}
              py="40px"
              sx={{ fontSize: { xs: "20px", md: "22px" } }}
            >
              No Signed Athlete
            </Typography>
          )}
        </Grid>
        <Grid>
          <Typography
            my="20px"
            color={theme.colors.common.white}
            fontFamily={theme.fonts.livvic}
            fontWeight={theme.fonts.fontWeightBold}
            sx={{ fontSize: { xs: "18px", md: "24px" }, paddingX: "30px" }}
          >
            Unsigned Athletes
          </Typography>
          {selectedAthleteData?.unsigned?.length > 0 ? (
            <Grid container spacing={4} p="10px" justifyContent="center">
              {selectedAthleteData?.unsigned?.map((detail, index) => (
                <Grid item key={index} xs={12} sm={10} md={9} lg={6} xl={4}>
                  <UnsignedathleteCard
                    detail={detail}
                    id={selectedAthleteData._id}
                    callApi={callApi}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography
              display="flex"
              justifyContent="center"
              alignItems="center"
              fontFamily={theme.fonts.livvic}
              fontWeight={500}
              py="40px"
              sx={{ fontSize: { xs: "20px", md: "22px" } }}
            >
              No Unsigned Athlete
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CampaignSelectedAthlete;

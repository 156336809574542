import React, { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogContent,
  Grid,
  Input,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import { ToastContainer, toast } from "react-toastify";
const ViewApplicantsPriceModal = ({
  handlePriceModalClose,
  selectedApplicantIds,
  totalPrice,
  handleAccept,
}) => {
  const theme = useTheme();
  const totalAthlete = selectedApplicantIds.length;
  const handleProceed = () => {
    if (totalAthlete > 0) {
      handleAccept();
      handlePriceModalClose();
    } else {
      toast.error("Please select athlete");
    }
  };

  return (
    <DialogContent
      sx={{
        paddingX: { xs: "10px", md: "30px" },
        backgroundColor: "#121212",
        border: `0.5px solid #121212`,
        color: "white",
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "6px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "18px" }}
        onClick={handlePriceModalClose}
      ></Box>
      <Typography
        mt="35px"
        mb="8px"
        fontWeight={500}
        fontFamily={theme.fonts.livvic}
        sx={{ fontSize: { xs: "18px", md: "20px" } }}
      >
        View price details
      </Typography>
      <Box px="25px" backgroundColor="#000000" border="1px solid #797979">
        <Typography
          mt="12px"
          fontWeight={theme.fonts.fontWeightRegular}
          fontFamily={theme.fonts.livvic}
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Athlete
          <Box component="span" fontWeight={theme.fonts.fontWeightRegular}>
            {totalAthlete}
            {/* {campaignData.totalCompensation} */}
          </Box>
        </Typography>
        <Typography
          mt="12px"
          fontWeight={theme.fonts.fontWeightRegular}
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            borderBottom: "0.5px solid #AFACAC",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: { xs: "16px", md: "18px" },
          }}
        >
          Amount
          <Box
            // color="#FF0101"
            component="span"
            fontWeight={theme.fonts.fontWeightRegular}
          >
            {totalAthlete !== 0 ? `$${totalPrice / totalAthlete}` : "$0"}
            {/* {campaignData?.applications?.length} */}
          </Box>
        </Typography>
        {/* <Typography
          mt="12px"
          fontWeight={theme.fonts.fontWeightRegular}
          fontFamily={theme.fonts.livvic}
          sx={{
            pb: "5px",
            borderBottom: "0.5px solid #AFACAC",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: { xs: "16px", md: "18px" },
          }}
        >
          Platform fee
          <Box component="span" fontWeight={theme.fonts.fontWeightRegular}>
           
            $200
          </Box>
        </Typography> */}
        <Typography
          mt="3px"
          mb="10px"
          fontSize="20px"
          fontWeight={theme.fonts.fontWeightRegular}
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "@media (max-width:600px)": {
              fontSize: "16px",
            },
          }}
        >
          Total
          <Box
            color="#FF0101"
            component="span"
            fontWeight={theme.fonts.fontWeightMedium}
          >
            ${totalAthlete !== 0 ? totalPrice : "0"}
          </Box>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end" mt="25px">
        <Button
          onClick={handleProceed}
          sx={{
            fontFamily: theme.fonts.livvic,
            fontWeight: 500,
            textTransform: "none",
            fontSize: { xs: "16px", md: "20px" },
            px: "80px",
            color: "white",
            background: "#F30000",
            border: "1px solid #F30000",
            "&:hover": {
              backgroundColor: "darkred",
            },
          }}
        >
          {" "}
          PROCEED
        </Button>
      </Box>
    </DialogContent>
  );
};

export default ViewApplicantsPriceModal;

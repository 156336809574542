import React from "react";
import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import CrossIcon from "../../../assets/icons/CrossIcon.svg";
import TrialImg from "../../../assets/images/TrialFree.png";

const TrialFreeModal = ({ handleDialogClose }) => {
  const theme = useTheme();

  const handleRegister = () => {
    handleDialogClose();
  };

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: theme.colors.background.formInput,
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer" }}
        onClick={handleDialogClose}
      ></Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          py: "40px",
          gap: 3,
        }}
      >
        <Box
          component="img"
          src={TrialImg}
          alt="Otp Verified"
          width="200px"
        ></Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          fontWeight={theme.fonts.fontWeightMedium}
          variant="h6"
        >
          Unlock 30-Day Free trial
        </Typography>
        <Typography fontFamily={theme.fonts.livvic} color="#D9D9D9">
          Don't miss this opportunity! Register now and get a 30-day free trial.
        </Typography>

        <Button
          // fullWidth
          sx={{
            background: theme.colors.background.registerButton,
            color: "white",
            height: { xs: "60px", md: "50px" },
            width: { xs: "60%", md: "50%" },
            mt: "10px",
            fontSize: { xs: "18px", md: "22px" },
            fontFamily: theme.fonts.livvic,
            fontWeight: theme.fonts.fontWeightMedium,
            borderRadius: "10px",
            "&:hover": {
              background: theme.colors.divider.red,
            },
          }}
          onClick={handleRegister}
        >
          REGISTER NOW
        </Button>
      </Box>
    </DialogContent>
  );
};

export default TrialFreeModal;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import BrowseFileIcon from "../../../../../../assets/icons/BrowseFileIcon.svg";
import DownloadBoldIcon from "../../../../../../assets/icons/DownloadBoldIcon.svg";
import { saveAs } from "file-saver";
import {
  getClickCount,
  getTrackingLink,
  uploadProof,
} from "../../../../../../services/campaign/campaign.service";
import { toast } from "react-toastify";
import {
  ContentCopyRounded,
  ExpandLess,
  ExpandMore,
  LibraryAddCheckRounded,
} from "@mui/icons-material";

// const AntSwitch = styled(Switch)(({ theme }) => ({
//   width: 28,
//   height: 16.5,
//   padding: 0,
//   display: "flex",
//   "&:active": {
//     "& .MuiSwitch-thumb": {
//       width: 16,
//     },
//     "& .MuiSwitch-switchBase.Mui-checked": {
//       transform: "translateX(9px)",
//     },
//   },

//   "& .MuiSwitch-switchBase": {
//     padding: 2,
//     color: "#0E0E0E",
//     "&.Mui-checked": {
//       transform: "translateX(12px)",
//       color: "white",
//       "& + .MuiSwitch-track": {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "green",
//       },
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
//     width: 12,
//     height: 12,
//     borderRadius: 6,
//     transition: theme.transitions.create(["width"], {
//       duration: 200,
//     }),
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 16 / 2,
//     opacity: 1,
//     backgroundColor:
//       theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "#797979",
//     boxSizing: "border-box",
//   },
// }));

const ActivityDetailsCard = ({
  detail,
  campaignId,
  fetchActivityDetails,
  isContractSigned,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const [trackingStatus, setTrackingStatus] = useState({
    link: "",
    totalCount: 0,
    uniqueCount: 0,
  });

  const [formData, setFormData] = useState({
    proofFile: detail?.proof?.proofFile,
    url: detail?.proof?.url || "",
    description: detail?.proof?.description || "",
  });

  let activities = [];
  detail?.deliverables?.forEach((deliverable) =>
    deliverable?.activities?.forEach((activity) => activities.push(activity))
  );
  const uniqueActivities = [...new Set(activities)];

  const handleProofFile = (e) => {
    const file = e.target.files[0];
    setFormData((preValue) => ({ ...preValue, proofFile: file }));
  };

  const handleOptional = (e) => {
    const { name, value } = e.target;
    setFormData((preValue) => ({ ...preValue, [name]: value }));
  };

  const handleStatus = () => {
    setStatus(!status);
  };

  const handleDownloadIconClick = (downloadLink, fileName) => {
    saveAs(downloadLink, fileName);
  };

  const handleCopyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(trackingStatus?.link);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleSend = async () => {
    if (!formData?.proofFile || !formData?.url) {
      toast.error("Please fill the required fields.");
    } else {
      setLoading(true);
      const response = await uploadProof({
        resend: detail?.proof?.submitionStatus ? true : false,
        campaignId,
        deliverableId: detail?._id,
        ...formData,
      });
      setLoading(false);
      fetchActivityDetails();

      if (response?.data?.status === 200) {
        toast.success(response?.data?.message);
      } else toast.error(response);
    }
  };

  const fetchTrackingStatus = async () => {
    const trackingLinkResponse = await getTrackingLink(
      campaignId,
      detail?._id,
      localStorage.getItem("userId"),
      detail?.deliverableUrl
    );
    const clickCountResponse = await getClickCount(
      campaignId,
      detail?._id,
      localStorage.getItem("userId")
    );

    setTrackingStatus((preValue) => ({
      ...preValue,
      link: trackingLinkResponse?.data?.data,
      totalCount: clickCountResponse?.data?.data[0]?.totalCount,
      uniqueCount: clickCountResponse?.data?.data[0]?.uniqueCount,
    }));
  };

  useEffect(() => {
    fetchTrackingStatus();
  }, []);

  return (
    <>
      <Grid
        my={{ xs: "10px", md: "20px" }}
        backgroundColor="black"
        border="1px solid #797979"
      >
        {/* <ToastContainer /> */}
        {detail?.proof?.brandResponse?.approved === false && (
          <Box
            backgroundColor={theme.colors.text.red}
            display="flex"
            justifyContent="center"
          >
            <Typography
              fontWeight={theme.fonts.fontWeightRegular}
              fontFamily={theme.fonts.livvic}
              fontSize={theme.fonts.cardSubHeading}
              padding="5px"
            >
              Proof has been rejected
            </Typography>
          </Box>
        )}
        <Grid
          sx={{
            px: { xs: "10px", sm: "20px" },
            py: { xs: "10px" },
          }}
        >
          <Box
            fontSize={theme.fonts.cardSubHeading}
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            Activities{" "}
            <Box
              component="span"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "white",
                width: { xs: "100%", sm: "calc(100% - 150px)" },
                textAlign: { sm: "right" },
              }}
            >
              {detail?.activities?.join(", ")}
            </Box>
          </Box>
          <Box
            mt="10px"
            fontSize={theme.fonts.cardSubHeading}
            color="#C5C5C5"
            fontFamily={theme.fonts.livvic}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            Compensation
            <Box
              color="#FF0101"
              fontWeight={theme.fonts.fontWeightRegular}
              fontSize={theme.fonts.cardHighlights}
            >
              {detail?.paymentModel === "Revenue Share" ? (
                <>
                  {detail?.compensation}%
                  <span style={{ fontSize: "15px" }}> of monthly revenue</span>
                </>
              ) : (
                `$${detail?.compensation}`
              )}
            </Box>
          </Box>
          <Box
            mt="10px"
            fontSize={theme.fonts.cardSubHeading}
            fontFamily={theme.fonts.livvic}
            color="#C5C5C5"
            sx={{
              display: "flex",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            Tracking Link
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
                background: "black",
                padding: "5px 10px",
                border: `1px solid ${theme.colors.text.greyDarker}`,
                borderRadius: "5px",
                maxWidth: { xs: "90%", sm: "300px" },
                color: "white",
              }}
            >
              <Box
                sx={{
                  fontFamily: theme.fonts.livvic,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {trackingStatus?.link}
              </Box>
              {isCopied ? (
                <LibraryAddCheckRounded />
              ) : (
                <ContentCopyRounded
                  onClick={handleCopyToClipboard}
                  sx={{ cursor: "pointer" }}
                />
              )}
            </Box>
          </Box>

          <Box
            mt="10px"
            fontSize={theme.fonts.cardSubHeading}
            color="#C5C5C5"
            fontFamily={theme.fonts.livvic}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: 1,
            }}
          >
            <Typography
              fontSize={theme.fonts.cardSubHeading}
              fontFamily={theme.fonts.livvic}
              width="120px"
            >
              Total Click
            </Typography>
            <Box
              sx={{
                fontWeight: theme.fonts.fontWeightRegular,
                fontSize: theme.fonts.cardHighlights,
                color: "white",
                // cursor: "pointer",
                // "&:hover": {
                //   textDecoration: "underline",
                //   textUnderlineOffset: "4px",
                // },
              }}
            >
              {trackingStatus?.totalCount}
            </Box>
          </Box>

          <Box
            mt="10px"
            fontSize={theme.fonts.cardSubHeading}
            color="#C5C5C5"
            fontFamily={theme.fonts.livvic}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: { xs: "wrap", sm: "nowrap" },
              gap: 1,
            }}
          >
            <Typography
              fontSize={theme.fonts.cardSubHeading}
              fontFamily={theme.fonts.livvic}
              width="120px"
            >
              Unique Click
            </Typography>
            <Box
              sx={{
                fontWeight: theme.fonts.fontWeightRegular,
                fontSize: theme.fonts.cardHighlights,
                color: "white",
              }}
            >
              {trackingStatus?.uniqueCount}
            </Box>
          </Box>

          <Box mt="10px">
            <Typography
              sx={{ fontSize: theme.fonts.cardSubHeading, color: "#C5C5C5" }}
            >
              Social Proof
            </Typography>
            <Grid
              container
              gap={1.2}
              sx={{ my: 1, justifyContent: "space-between" }}
            >
              {detail?.additionalProjectFiles?.map((file, index) => {
                const filesData = file.split("/");
                return (
                  <Grid item xs={10} sm={5.5} key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "#414141",
                        borderRadius: "5px",
                        border: "1px solid #797979",
                        py: "5px",
                        px: "10px",
                        width: "100%",
                      }}
                    >
                      {/* -----------------------Contract Icon and ImageName----------------------- */}
                      <Box
                        display="flex"
                        alignItems="center"
                        width="calc(100% - 24px)"
                      >
                        <Box
                          sx={{
                            background: "rgba(217,217,217,0.85)",
                            borderRadius: "5px",
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                            py: 2,
                            px: 1.5,
                            mr: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <InsertDriveFileOutlinedIcon
                            sx={{
                              color: "rgba(61,44,44,1)",
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            color: "#C5C5C5",
                            fontSize: { xs: "16px", md: "18px" },
                            fontWeight: theme.fonts.fontWeightRegular,
                            fontFamily: theme.fonts.livvic,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {filesData[filesData.length - 1]}
                        </Typography>
                      </Box>
                      {/* --------------------Download Icon-------------------------- */}
                      <Box
                        component="img"
                        src={DownloadBoldIcon}
                        alt="Download Icon"
                        sx={{
                          cursor: "pointer",
                          width: "24px",
                        }}
                        onClick={() =>
                          handleDownloadIconClick(
                            file,
                            filesData[filesData.length - 1]
                          )
                        }
                      ></Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Grid>

        {detail?.proof?.brandResponse?.approved === false && (
          <Box
            sx={{
              px: { xs: "10px", sm: "20px" },
              py: "10px",
              borderTop: `1px solid ${theme.colors.text.greyDark}`,
              borderBottom: `1px solid ${theme.colors.text.greyDark}`,
            }}
          >
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightMedium}
              fontSize="20px"
              color={theme.colors.text.red}
              mb={1}
            >
              Rejected Reason
            </Typography>
            <Typography
              fontFamily={theme.fonts.livvic}
              color={theme.colors.text.greyLight}
              fontSize="18px"
            >
              {detail?.proof?.brandResponse?.rejectionReason}
            </Typography>
          </Box>
        )}

        <Box sx={{ paddingX: { xs: "10px", sm: "20px" }, pb: "10px" }}>
          {isContractSigned && (
            <Box
              onClick={handleStatus}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {status ? <ExpandLess /> : <ExpandMore />}
            </Box>
          )}

          {status && (
            <>
              <Grid mt="20px">
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Proof file
                </Typography>
                <Box
                  component="label"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    background: theme.colors.background.formInput,
                    borderRadius: theme.fonts.smallBorderRadius,
                    cursor: "pointer",
                    border: `1px solid ${theme.colors.common.white}`,
                  }}
                >
                  <Box
                    sx={{
                      width: "50px",
                      height: "50px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      component="img"
                      src={BrowseFileIcon}
                      alt="Browse File Icon"
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></Box>
                    <Input
                      type="file"
                      // inputProps={{ accept: "image/*" }}
                      onChange={handleProofFile}
                      sx={{ display: "none" }}
                    />
                  </Box>
                  {formData?.proofFile ? (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{ paddingY: "10px", paddingX: "5px" }}
                    >
                      {typeof formData?.proofFile === "string"
                        ? formData?.proofFile
                        : formData?.proofFile?.name}
                    </Typography>
                  ) : (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{
                        color: "#C5C5C5",
                        paddingY: "10px",
                        paddingX: "5px",
                      }}
                    >
                      Select
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid mt="20px">
                <Typography
                  sx={{
                    color: "white",
                    fontSize: { xs: "18px", md: "20px" },
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontFamily: theme.fonts.livvic,
                  }}
                >
                  URL
                </Typography>
                <TextField
                  name="url"
                  value={formData?.url}
                  placeholder="Enter proof url"
                  multiline
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    sx: {
                      border: `1px solid ${theme.colors.common.white}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: "10px",
                    },
                  }}
                  sx={{
                    marginTop: "5px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                  }}
                  onChange={handleOptional}
                />
              </Grid>
              <Grid mt="20px">
                <Typography
                  sx={{
                    color: "white",
                    fontSize: { xs: "18px", md: "20px" },
                    fontWeight: theme.fonts.fontWeightMedium,
                    fontFamily: theme.fonts.livvic,
                  }}
                >
                  Description
                  <span
                    style={{
                      color: "#D9D9D9",
                      fontWeight: theme.fonts.fontWeightRegular,
                    }}
                  >
                    (optional)
                  </span>
                </Typography>
                <TextField
                  name="description"
                  value={formData?.description}
                  multiline
                  rows={6}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    sx: {
                      border: `1px solid ${theme.colors.common.white}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: "10px",
                    },
                  }}
                  sx={{
                    marginTop: "5px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                  }}
                  onChange={handleOptional}
                />
              </Grid>
              {detail?.proof?.brandResponse?.approved !== true && (
                <Grid display="flex" justifyContent="end" mt="30px">
                  <Button
                    sx={{
                      paddingX: "10%",
                      background: "#FF0101",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "darkred",
                      },
                      fontFamily: theme.fonts.montserrat,
                      fontWeight: theme.fonts.fontWeightBold,
                      textTransform: "none",
                      fontSize: { xs: "18px", md: "24px" },
                      "&.MuiButton-root.Mui-disabled": {
                        background: "rgba(255,0,0,0.6)",
                        color: "white",
                      },
                    }}
                    onClick={handleSend}
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress sx={{ color: "gray" }} />
                    ) : detail?.proof?.submitionStatus ? (
                      "RESEND"
                    ) : (
                      "SEND"
                    )}
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Box>

        {detail?.proof?.submitionStatus &&
          detail?.proof?.brandResponse?.approved !== false && (
            <Box
              backgroundColor={
                detail?.proof?.brandResponse?.approved ? "#23AC00" : "#E74500"
              }
              display="flex"
              justifyContent="center"
            >
              <Typography
                fontWeight={theme.fonts.fontWeightRegular}
                fontFamily={theme.fonts.livvic}
                fontSize={{ xs: "14px", sm: "18px" }}
                padding="5px"
              >
                {detail?.proof?.brandResponse?.approved
                  ? "Proof has been accepted by brand"
                  : "Proof has been sent to brand"}
              </Typography>
            </Box>
          )}
      </Grid>
    </>
  );
};

export default ActivityDetailsCard;

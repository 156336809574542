const Joi = require("joi");

const Schema = Joi.object({
  firstName: Joi.string()
    .required()
    .regex(/^[a-zA-Z\s.'\-À-ÖØ-öø-ÿ]*$/)
    .trim()
    .max(30)
    .messages({
      "string.max": `First name must be at most 30 characters long`,
      "any.required": `First name is required`,
      "string.empty": `First name cannot be empty`,
      "string.pattern.base": `First name must contains only alphabets`,
    }),
  lastName: Joi.string()
    .required()
    .regex(/^[a-zA-Z\s.'\-À-ÖØ-öø-ÿ]*$/)
    .trim()
    .max(30)
    .messages({
      "string.max": `Last name must be at most 30 characters long`,
      "any.required": `Last name is required`,
      "string.empty": `Last name cannot be empty`,
      "string.pattern.base": `Last name must contains only alphabets`,
    }),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .max(50)
    .required()
    .messages({
      "any.required": `Email is required`,
      "string.empty": `Email cannot be empty`,
      "string.pattern.base": `Email is Invalid`,
      "string.max": `Email must be at most 50 characters long`,
    }),
  countryCode: Joi.string(),
  phoneNo: Joi.string()
    .trim()
    .regex(/^\d+$/)
    .required()
    .min(7)
    .max(15)
    .messages({
      "string.max": `Phone number must be at most 15 characters long`,
      "string.min": `Phone number must be minimum 7 characters long`,
      "any.required": `Phone number is required`,
      "string.empty": `Phone number cannot be empty`,
      "string.pattern.base": `Phone number is Invalid`,
    }),
  password: Joi.string()
    .trim()
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[()@$!%*?&])[A-Za-z\d()@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": "Password is required.",
      "string.empty": "Password cannot be empty.",
      "string.pattern.base":
        "Password must be at least 8 characters long, include one lowercase letter, one number, and one special character (@$!%*?&).",
    }),
  confirmPassword: Joi.string()
    .trim()
    .regex(/^(?=.*[a-z])(?=.*\d)(?=.*[()@$!%*?&])[A-Za-z\d()@$!%*?&]{8,}$/)
    .required()
    .messages({
      "any.required": "Confirm password is required.",
      "string.empty": "Confirm password cannot be empty.",
      "string.pattern.base":
        "Confirm password must be at least 8 characters long, include one lowercase letter, one number, and one special character (@$!%*?&).",
    }),
});

export default Schema;

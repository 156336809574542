export const PADDING_X = "60px";

export const NAVBAR_HEIGHT = "120px";

export const BURRST_SITE = "https://burrst.io";

// export const HOST_URL = BURRST_SITE;
export const HOST_URL = "https://burrst.utkallabs.com"; // need to change this host URL
// export const HOST_URL = "http://localhost:3000";
// export const HOST_URL = "https://dt12hcvr-3000.inc1.devtunnels.ms";

export const BASE_API_URL =
  HOST_URL === BURRST_SITE
    ? `https://admin.burrst.io/api/v1`
    : `https://admin.burrst.io/api/sandbox/v1`;

export const BASE_IMAGE_URL = "https://admin.burrst.io/api/v1/static/images";

export const BASE_VIDEO_URL = "https://admin.burrst.io/api/v1/static/videos";

export const REDIRECT_TO_DASHBOARD = `${HOST_URL}/dashboard`;

export const REDIRECT_TO_SUBSCRIPTION = `${HOST_URL}/subscription`;

export const REDIRECT_TO_VIEW_APPLICANTS = `${HOST_URL}/dashboard/campaigns/view-applicants`;

export const REDIRECT_TO_MY_ACCOUNTS = `${HOST_URL}/dashboard/home`;

export const REDIRECT_TO_USERS_LIST = `${HOST_URL}/dashboard/users`;

export const BRAND = "BRAND";

export const ATHLETE = "ATHLETE";

export const USER_ID = "userId";

export const OTP_TIMER = 60;

import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CampaignsCard from "../subComponents/campaigns/campaignsCard";
import Pulse from "../../../../../themes/@overrides/pulse";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { getBurrstRecommendedList } from "../../../../../services/campaign/campaign.service";
import { Navigation } from "swiper/modules";

const Campaigns = () => {
  const theme = useTheme();

  const navigate = useNavigate();

  const [burrstRecommendedList, setBurrstRecommendedList] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const handleViewAll = () => {
    navigate("/dashboard/campaigns");
  };

  const fetchCampaignsList = async () => {
    setIsAllDataLoading(true);
    const { data } = await getBurrstRecommendedList();
    setIsAllDataLoading(false);
    setBurrstRecommendedList(data?.data[0]?.burrstRecommended);
  };

  useEffect(() => {
    fetchCampaignsList();
  }, []);

  return (
    <Box
      sx={{
        px: { xs: "10px", sm: "30px" },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        mt="60px"
        mb="30px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Pulse />
          <Typography
            color={theme.colors.common.white}
            fontSize={theme.fonts.heading}
            fontFamily={theme.fonts.russoOne}
          >
            Campaigns
          </Typography>
        </Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.creamLight}
          sx={{ cursor: "pointer" }}
          onClick={handleViewAll}
        >
          View All
        </Typography>
      </Box>

      <Swiper
        grabCursor={true}
        navigation={true}
        modules={[Navigation]}
        slidesPerView={2.2}
        initialSlide={0}
        breakpoints={{
          0: { slidesPerView: 1, spaceBetween: 40 },
          600: { slidesPerView: 1.5, spaceBetween: 20 },
          900: { slidesPerView: 1.8, spaceBetween: 20 },
          1200: { slidesPerView: 2.5, spaceBetween: 20 },
          1500: { slidesPerView: 3, spaceBetween: 20 },
        }}
        style={{ padding: "0 20px" }}
      >
        {isAllDataLoading ? (
          Array.from({ length: 10 }, (_, index) => (
            <SwiperSlide key={index}>
              <CampaignsCard isAllDataLoading={isAllDataLoading} />
            </SwiperSlide>
          ))
        ) : burrstRecommendedList?.length ? (
          burrstRecommendedList?.map((detail, index) => (
            <SwiperSlide key={index}>
              <CampaignsCard
                detail={detail}
                isAllDataLoading={isAllDataLoading}
              />
            </SwiperSlide>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              fontSize: { xs: "24px", sm: "30px" },
              minHeight: "200px",
              width: "100%",
              background: "rgba(18,18,18,1)",
              borderRadius: "5px",
              mb: "30px",
            }}
          >
            <Box
              component="img"
              src={NoActiveCampaign}
              alt="No Active Campaign"
              sx={{ width: { xs: "70px", sm: "100px" } }}
            ></Box>
            No campaigns yet!
          </Box>
        )}
      </Swiper>
    </Box>
  );
};

export default Campaigns;

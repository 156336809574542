import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  Input,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dayjs from "dayjs";

// import BurrstLogo from "../../../assets/logo/BurrstLogo.svg";
import MyProfileAthleteBackground from "../../../assets/images/BackgroundImage2.png";
import RegistrationBackground from "../../../assets/images/RegistrationBackground.jpg";

import AthleteImageUploadIcon from "../../../assets/icons/AthleteImageUploadIcon.svg";
import MyProfileAthleteIcon from "../../../assets/icons/MyProfileAthleteIcon.svg";

import SentOtpModal from "../../../components/auth/subComponent/sentOtpModal";

import { PADDING_X, BASE_IMAGE_URL } from "../../../utils/constants";

import { sportList } from "../../../services/admin/admin.service";
import {
  dataCityList,
  dataCountryList,
  dataInterestList,
  dataStateList,
} from "../../../services/data/data.service";
import { toast } from "react-toastify";
import Schema from "../../../joi.schema/beforeLogin/athlete/athlete.MyProfile.Schema";

import { athleteUpdateProfileDetails } from "../../../services/athlete/athlete.service";
import { getToken } from "../../../routes";

const MyProfileAthlete = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const token = location?.state?.registrationToken || getToken();
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  const [loading, setLoading] = useState(false);

  const detail = location?.state?.detail;
  const isMyProfile = location?.state?.isMyProfile;

  const tokenName = isMyProfile ? "loginToken" : "registrationToken";

  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [sportsOptions, setSportsOptions] = useState([]);
  const [interestOptions, setInterestOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isCountryChange, setIsCountryChange] = useState(false);
  const [isStateChange, setIsStateChange] = useState(false);

  const [formData, setFormData] = useState({
    profileImage: detail?.profileImage || null,
    dateOfBirth: detail?.dob ? dayjs(detail?.dob) : null,
    gender: detail?.gender || "",
    educationInstitutionType: detail?.educationInstitutionType || "",
    school: detail?.school || "",
    grade: detail?.grade || "",
    sports: detail?.sports || [],
    interests: detail?.interests || [],
    country: detail?.country || "",
    state: detail?.state || "",
    city: detail?.city || "",
    [tokenName]: token,
  });

  const handleSelectionsChange = (field, value) => {
    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: value,
    }));
  };

  const handleOtpModalClose = () => {
    setOtpModalOpen(false);
  };
  const handleBurrstLogo = () => {
    navigate("/");
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      profileImage: file,
    });
  };

  const handleNext = async () => {
    // setFormData({
    //   ...formData,
    //   dateOfBirth: detail?.dob,
    // });

    const { error } = Schema.validate({
      ...formData,
      dateOfBirth: detail?.dob ? detail?.dob : formData?.dateOfBirth,
    });

    if (!formData?.profileImage) {
      toast.error("Please Select Profile Image");
    } else if (error) {
      error?.details?.forEach((errors) => {
        toast.error(errors.message);
      });
    } else {
      setLoading(true);
      const response = await athleteUpdateProfileDetails({
        ...formData,
        sports: formData?.sports.join(","),
        interests: formData?.interests.join(","),
      });
      setLoading(false);
      if (response?.status === 200) {
        window.localStorage.setItem(
          "registrationToken",
          response?.data?.registrationToken
        );
        isMyProfile
          ? navigate("/dashboard/home/my-profile", {
              replace: true,
              state: { isMyProfile: true },
            })
          : navigate("/athletes/public-profile", {
              state: {
                registrationToken: response?.data?.registrationToken,
              },
            });
      } else {
        toast.error(response?.message);
      }
    }
  };

  const fetchSportsOptions = async () => {
    const response = await sportList(token);

    if (response?.status === 200) {
      setSportsOptions(response?.data);
    } else {
      console.error("Failed to fetch sports options:", response.message);
    }
  };

  const fetchInterestOptions = async () => {
    const response = await dataInterestList(token);

    if (response?.status === 200) {
      setInterestOptions(response.data);
    } else {
      console.error("Failed to fetch interest options:", response?.message);
    }
  };

  const fetchCountryOptions = async () => {
    const response = await dataCountryList("");

    if (response?.status === 200) {
      setCountryOptions(response?.data);
    } else {
      console.error("Failed to fetch country options:", response?.message);
    }
  };

  const fetchStateOptions = async () => {
    const response = await dataStateList(formData?.country);

    if (response?.status === 200) {
      setStateOptions(response?.data);
    } else {
      console.error("Failed to fetch country options:", response?.message);
    }
  };

  const fetchCityOptions = async () => {
    const response = await dataCityList(formData?.country, formData?.state);

    if (response?.status === 200) {
      setCityOptions(response?.data);
    } else {
      console.error("Failed to fetch country options:", response?.message);
    }
  };

  useEffect(() => {
    fetchSportsOptions();
    fetchInterestOptions();
    fetchCountryOptions();

    // for editing this page
    if (isMyProfile) {
      fetchStateOptions();
      fetchCityOptions();
    }
  }, []);

  useEffect(() => {
    if (isCountryChange) {
      setFormData((preValue) => ({ ...preValue, state: "", city: "" }));
      setCityOptions([]);
      if (formData?.country) {
        fetchStateOptions();
      }
      setIsCountryChange(false);
    }
  }, [formData?.country]);

  useEffect(() => {
    if (isStateChange) {
      setFormData((preValue) => ({ ...preValue, city: "" }));
      if (formData?.country && formData?.state) {
        if (stateOptions.length) fetchCityOptions();
      }
      setIsStateChange(false);
    }
  }, [formData?.state]);
  return (
    <>
      <Helmet>
        <title>My Profile Athlete | Burrst</title>
      </Helmet>
      <Box>
        <Box
          component="img"
          src={RegistrationBackground}
          alt="MyProfileAthlete Background"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            objectFit: "cover",
            zIndex: "-1",
            height: "100vh",
            width: "80vw",
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          sx={{
            py: "40px",
            paddingX: PADDING_X,
            "@media (max-width:600px)": {
              paddingX: "10px",
            },
          }}
        >
          <Box
            sx={{
              marginBottom: "2%",
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              component="img"
              src={BurrstLogo}
              onClick={handleBurrstLogo}
              alt="Burrst Logo"
              width="200px"
              style={{
                cursor: "pointer",
              }}
            ></Box>
          </Box>
          <Grid container spacing={3} padding="15px">
            <Grid item xs={10}>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontSize="26px"
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  marginBottom: "2%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Basic Information
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "120px",
                  width: "120px",
                  background: theme.colors.background.formInput,
                  borderRadius: theme.fonts.smallBorderRadius,
                  position: "relative",
                }}
              >
                {formData?.profileImage ? (
                  <Box
                    component="img"
                    src={
                      isMyProfile
                        ? formData?.profileImage
                        : URL.createObjectURL(formData?.profileImage)
                    }
                    alt="My Profile Athlete"
                    sx={{
                      width: "100%",
                      height: "100%",
                      borderRadius: theme.fonts.smallBorderRadius,
                    }}
                  ></Box>
                ) : (
                  <Box
                    component="img"
                    src={MyProfileAthleteIcon}
                    alt="My Profile Athlete Avatar"
                    sx={{
                      width: "80%",
                      height: "80%",
                    }}
                  ></Box>
                )}

                {!isMyProfile && (
                  <Box
                    component="label"
                    sx={{
                      position: "absolute",
                      bottom: -8,
                      right: -15,
                      width: "40px",
                      height: "40px",
                      background: theme.colors.background.red100,
                      borderRadius: "50%",
                      padding: "10px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "black",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      src={AthleteImageUploadIcon}
                      alt="Athlete Image Upload Icon"
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></Box>

                    <Input
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      onChange={handleImageChange}
                      sx={{ display: "none" }}
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Date of birth
              </Typography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="dateOfBirth"
                  placeholder="MM/DD/YYYY"
                  value={formData?.dateOfBirth}
                  onChange={(selectedDate) =>
                    handleSelectionsChange(
                      "dateOfBirth",
                      // dayjs(selectedDate).format("MM/DD/YYYY")
                      new Date(selectedDate).toLocaleString()
                    )
                  }
                  format="MM/DD/YYYY"
                  autoComplete="off"
                  disableFuture
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                    width: "100% !important",
                    ".MuiInputBase-root,button": {
                      color: theme.colors.text.greyLight,
                    },

                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                          borderRadius: theme.fonts.smallBorderRadius,
                        },
                      },
                    },
                  }}
                  slotProps={{
                    layout: {
                      sx: {
                        ".MuiDateCalendar-root": {
                          color: theme.colors.common.white,
                          backgroundColor: theme.colors.background.formInput,
                        },
                        ".MuiPickersCalendarHeader-switchViewButton": {
                          color: theme.colors.common.white,
                        },
                        ".MuiDayCalendar-weekDayLabel": {
                          color: theme.colors.common.white,
                        },
                        ".MuiPickersDay-root": {
                          color: theme.colors.text.greyLight,
                          "&.Mui-disabled": {
                            color: theme.colors.text.greyDarker,
                          },
                        },
                        ".MuiSvgIcon-root": {
                          color: "white",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Gender
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="gender"
                options={["Male", "Female", "Non-Binary"]}
                value={formData?.gender}
                onChange={(event, newValue) =>
                  handleSelectionsChange("gender", newValue)
                }
                // error={!!formErrors.gender}
                // helperText={formErrors.gender}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  },

                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
                    backgroundColor: "#363636",
                  },
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
                    {
                      backgroundColor: "#4396e6",
                    },
                  "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected ='true'] .Mui-focused":
                    {
                      backgroundColor: "#3878b4",
                    },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Educational Institution
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="educationInstitutionType"
                value={formData?.educationInstitutionType}
                onChange={(event, newValue) =>
                  handleSelectionsChange("educationInstitutionType", newValue)
                }
                // error={!!formErrors.sports}
                // helperText={formErrors.sports}
                options={["High-School", "College", "Professional"]}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  height: "30px",
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                {formData?.educationInstitutionType !== "Professional"
                  ? "Institution"
                  : "Team"}
              </Typography>

              <TextField
                name="school"
                placeholder={
                  formData?.educationInstitutionType !== "Professional"
                    ? "Institution name"
                    : "Team Name"
                }
                fullWidth
                // autoComplete="off"
                value={formData?.school}
                onChange={(e) =>
                  handleSelectionsChange("school", e.target.value)
                }
                // error={!!formErrors.school}
                // helperText={formErrors.school}
                InputProps={{
                  sx: {
                    border: `1px solid white`,
                    background: theme.colors.background.formInput,
                    color: "white",
                    borderRadius: "10px",
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Grade
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="grade"
                options={[
                  "Freshman",
                  "Sophomore",
                  "Junior",
                  "Senior",
                  "Professional",
                ]}
                value={formData?.grade}
                onChange={(event, newValue) =>
                  handleSelectionsChange("grade", newValue)
                }
                // error={!!formErrors.country}
                // helperText={formErrors.country}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Sports{" "}
                <span
                  style={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: "16px",
                    fontWeight: theme.fonts.fontWeightRegular,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  (Minimum Three)
                </span>
              </Typography>

              <Autocomplete
                color="green"
                fullWidth
                required
                name="sports"
                multiple
                freeSolo
                value={formData?.sports}
                onChange={(event, newValue) =>
                  handleSelectionsChange("sports", newValue)
                }
                // error={!!formErrors.sports}
                // helperText={formErrors.sports}
                options={sportsOptions}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                      sx={{
                        color: theme.colors.common.white,
                        backgroundColor:
                          theme.colors.background
                            .autocompleteRenderTagsLightgray,
                      }}
                    />
                  ))
                }
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        color: "white",
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Interests{" "}
                <span
                  style={{
                    fontFamily: theme.fonts.livvic,
                    fontSize: "16px",
                    fontWeight: theme.fonts.fontWeightRegular,
                    color: theme.colors.text.greyDark,
                  }}
                >
                  (Minimum Five)
                </span>
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="interest"
                multiple
                value={formData?.interests}
                onChange={(event, newValue) =>
                  handleSelectionsChange("interests", newValue)
                }
                // error={!!formErrors.interests}
                // helperText={formErrors.interests}
                options={interestOptions}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                      sx={{
                        color: theme.colors.common.white,
                        backgroundColor:
                          theme.colors.background
                            .autocompleteRenderTagsLightgray,
                      }}
                    />
                  ))
                }
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Country
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="country"
                options={countryOptions}
                value={formData?.country}
                onChange={(event, newValue) => {
                  setIsCountryChange(true);
                  handleSelectionsChange("country", newValue);
                }}
                // error={!!formErrors.country}
                // helperText={formErrors.country}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                State
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="state"
                options={stateOptions.length ? stateOptions : ["N/A"]}
                value={formData?.state}
                onChange={(event, newValue) => {
                  setIsStateChange(true);
                  handleSelectionsChange("state", newValue);
                }}
                // error={!!formErrors.state}
                // helperText={formErrors.state}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                City
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="city"
                options={cityOptions.length ? cityOptions : ["N/A"]}
                value={formData?.city}
                onChange={(event, newValue) =>
                  handleSelectionsChange("city", newValue)
                }
                // error={!!formErrors.city}
                // helperText={formErrors.city}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} textAlign="center">
              <Button
                fullWidth
                sx={{
                  background: theme.colors.background.registerButton,
                  color: theme.colors.common.white,
                  height: "50px",
                  maxWidth: "400px",
                  fontSize: "22px",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  borderRadius: "10px",
                  mt: 3,
                  py: 3.5,
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                }}
                disabled={loading}
                onClick={handleNext}
              >
                {loading ? (
                  <CircularProgress size={40} sx={{ color: "white" }} />
                ) : isMyProfile ? (
                  "Save"
                ) : (
                  "Next"
                )}
              </Button>
              <Dialog
                open={otpModalOpen}
                onClose={handleOtpModalClose}
                PaperProps={{
                  sx: {
                    width: "80vw",
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                sx={{
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgb(255,255,255,0.3)",
                }}
              >
                <SentOtpModal handleOtpModalClose={handleOtpModalClose} />
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MyProfileAthlete;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ViewAplicantsCard from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/viewAplicants.cards";
import {
  getappliedAthletes,
  respondToApplicant,
} from "../../../../../services/campaign/campaign.service";
import ViewApplicantsPriceModal from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/viewApplicantsPrice.modal";
import { toast } from "react-toastify";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";

const Viewapplicants = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [applicantData, setApplicantData] = useState([]);
  const [viewPriceModal, setViewPriceModal] = useState(false);
  const [selectedApplicantIds, setSelectedApplicantIds] = useState([]);
  const [ignoreApplicantIds, setIgnoreApplicantIds] = useState([]);
  const [acceptedListToUrl, setAcceptedListToUrl] = useState({});
  const totalPrice = applicantData?.totalCompensation;
  const sharePercentage = applicantData?.sharePercentage;
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const backState = localStorage.getItem("route");
  const campaignId = localStorage.getItem("campaignId");
  const back = "viewDetails";

  const handleBack = () => {
    if (backState === "viewDetails") {
      navigate(`/dashboard/campaigns/view-details/${campaignId}`);
    } else {
      navigate(-1);
    }
  };

  const handleAiRecommended = () => {
    navigate("/dashboard/campaigns/ai-recommended");
  };

  const handlePriceModalClose = () => {
    setViewPriceModal(false);
  };

  const handlePriceModalOpen = () => {
    setViewPriceModal(true);
  };

  useEffect(() => {
    callApi();
  }, []);

  const callApi = async () => {
    try {
      setDataLoading(true);
      const response = await getappliedAthletes();
      setDataLoading(false);
      if (response?.status === 200) {
        setApplicantData(response?.data);
      } else {
        console.error("Failed to fetch applicant details:", response?.message);
      }
    } catch (error) {
      console.error("Error fetching applicant details:", error);
    }
  };

  const confirmAction = async () => {
    return new Promise((resolve) => {
      const confirmed = window.confirm(
        "Are you sure? This will deduct money from your saved payment method."
      );
      resolve(confirmed);
    });
  };

  const handleAccept = async () => {
    if (!applicantData?.athletes?.disabled) {
      if (!applicantData.multipleAthletes && selectedApplicantIds.length > 1) {
        toast.error("You can only select one athlete for this campaign.", {
          autoClose: 5000,
          onClose: () => {
            window.location.reload();
          },
        });
        return;
      }
      try {
        let response;

        if (selectedApplicantIds?.length > 0) {
          const confirmed = await confirmAction();
          if (confirmed) {
            setLoading(true);
            response = await respondToApplicant(
              selectedApplicantIds,
              ignoreApplicantIds,
              acceptedListToUrl
            );
            setLoading(false);
          }
        } else {
          response = await respondToApplicant(
            selectedApplicantIds,
            ignoreApplicantIds
          );
        }
        setSelectedApplicantIds([]);
        setIgnoreApplicantIds([]);
        if (response?.status === 200) {
          await callApi();
        } else if (
          response?.status === 400 &&
          response?.data?.reason === "NOT_IN_APPLICATIONS"
        ) {
          toast.error(
            "Oops! It seems the athlete has already withdrawn their application.",
            {
              autoClose: 5000,
              onClose: () => {
                window.location.reload();
              },
            }
          );
        } else if (
          response?.status === 400 &&
          response?.data?.reason === "MULTIPLE_ATHELTES_NOT_ALLOWED"
        ) {
          toast.error("You have already selected athlete for your campaign");
        } else if (
          response?.status === 400 &&
          response?.data?.reason === "PAYMENT_FAILURE"
        ) {
          window.open(response?.data?.link, "_self");
          localStorage.setItem("route", back);
        } else {
          console.error("Failed to accept applicant :", response?.message);
        }
      } catch (error) {
        console.error("Error while accepting applicant:", error);
      }
    } else {
      alert("Please select athlete for this campaign.");
    }
  };

  return (
    <Grid
      mt="115px"
      sx={{
        justifyContent: "space-between",
        paddingX: "3%",
        mb: "2%",
        "@media (max-width:468px)": {
          paddingX: "10px",
        },
      }}
    >
      {" "}
      {/* <ToastContainer position="top-right" autoClose={5000} /> */}
      <Grid my="20px" display="flex" alignItems="center">
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: { xs: "35px", md: "40px" },
              color: "white",
            }}
          />
        </Box>
        <Typography
          color={theme.colors.common.white}
          fontFamily={theme.fonts.russoOne}
          ml="15px"
          sx={{
            fontSize: { xs: "25px", md: "35px" },
          }}
        >
          View Applicants
        </Typography>
      </Grid>
      <Grid backgroundColor="#121212" paddingTop="1%">
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: "3px",
            marginX: "2.5%",
            marginY: "25px",
            borderRadius: "50px",
            border: "1px solid #900505",
            background:
              "linear-gradient(0deg, #000000, #000000), linear-gradient(0deg, #900505, #900505)",
          }}
        >
          <Button
            sx={{
              width: "50%",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              textTransform: "none",
              fontSize: "22px",
              color: "white",
              background: "red",
              borderRadius: "50px",
              "&:hover": {
                backgroundColor: "darkred",
              },
              "@media (max-width:468px)": {
                fontSize: "14px",
              },
            }}
          >
            View Applicants
          </Button>
          <Button
            onClick={() => handleAiRecommended()}
            sx={{
              width: "50%",
              borderRadius: "50px",
              fontFamily: theme.fonts.livvic,
              fontWeight: theme.fonts.fontWeightMedium,
              textTransform: "none",
              fontSize: "22px",
              color: "#C5C5C5",
              "@media (max-width:468px)": {
                fontSize: "14px",
              },
            }}
          >
            AI recommended
          </Button>
        </Grid>
        {dataLoading ? (
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            alignItems="center"
            gap="40px"
            width="100%"
            px="70px"
            mb="20px"
          >
            {[...Array(2)].map((_, index) => (
              <CustomSkeleton
                key={index}
                sx={{
                  width: { xs: "100%", md: "30%" },
                  height: "350px",
                  borderRadius: "5px",
                }}
              />
            ))}
          </Box>
        ) : applicantData?.athletes?.length > 0 ? (
          <Grid
            container
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            sx={{
              height: "50vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              // "&::-webkit-scrollbar-thumb": {
              //   background: "grey",
              //   borderRadius: "4px",
              // },
            }}
          >
            {applicantData?.athletes?.map((details, index) => (
              <ViewAplicantsCard
                detail={details}
                key={index}
                setSelectedApplicantIds={setSelectedApplicantIds}
                setIgnoreApplicantIds={setIgnoreApplicantIds}
                setAcceptedListToUrl={setAcceptedListToUrl}
              />
            ))}
          </Grid>
        ) : (
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontFamily={theme.fonts.livvic}
            fontWeight={500}
            py="100px"
            sx={{ fontSize: { xs: "20px", md: "22px" } }}
          >
            No Applied Athlete
          </Typography>
        )}
        <Grid
          width="100%"
          backgroundColor="#0E0E0E"
          display="flex"
          justifyContent={
            applicantData?.athletes?.length > 0 ? "space-between" : "end"
          }
          flexWrap="wrap"
          paddingX="40px"
          paddingY="10px"
          alignItems="center"
          boxShadow="2px -10px 35px -18px #FFFFFF40"
        >
          {applicantData?.athletes?.length > 0 && (
            <Box display="flex" flexDirection="column">
              {sharePercentage ? null : (
                <>
                  <Typography
                    fontFamily={theme.fonts.livvic}
                    fontWeight={400}
                    color="#FF0101"
                    sx={{ fontSize: { xs: "18px", md: "20px" } }}
                  >
                    ${totalPrice || 0}
                  </Typography>
                  <Typography
                    onClick={handlePriceModalOpen}
                    fontFamily={theme.fonts.livvic}
                    fontWeight={400}
                    color="#7DA0FB"
                    sx={{
                      fontSize: { xs: "18px", md: "20px" },
                      cursor: "pointer",
                    }}
                  >
                    View price details
                  </Typography>
                </>
              )}
            </Box>
          )}
          <Box>
            {selectedApplicantIds?.length > 0 ||
            ignoreApplicantIds?.length > 0 ? (
              <Button
                onClick={handleAccept}
                disabled={loading}
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  textTransform: "none",
                  fontSize: { xs: "16px", md: "20px" },
                  px: "80px",
                  color: "white",
                  background: "#F30000",
                  border: "1px solid #F30000",
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                }}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "white" }} />
                ) : (
                  "PROCEED"
                )}
              </Button>
            ) : (
              <Button
                sx={{
                  fontFamily: theme.fonts.livvic,
                  fontWeight: 500,
                  textTransform: "none",
                  fontSize: { xs: "16px", md: "20px" },
                  px: "80px",
                  border: "1px solid #F30000",
                  "&:hover": {
                    backgroundColor: "#F30000",
                  },
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                }}
                disabled={true}
              >
                PROCEED
              </Button>
            )}
          </Box>
        </Grid>
        <Dialog
          open={viewPriceModal}
          onClose={handlePriceModalClose}
          sx={{
            backgroundColor: "rgb(255,255,255,0.2)",
            "& .MuiDialog-paper": {
              width: { xs: "90%", sm: "80%", md: "70%", lg: "50%" },
              maxWidth: "unset",
              height: "400px",
              //maxHeight: "unset",
            },
          }}
        >
          <ViewApplicantsPriceModal
            handlePriceModalClose={handlePriceModalClose}
            selectedApplicantIds={selectedApplicantIds}
            totalPrice={totalPrice}
            handleAccept={handleAccept}
          />
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default Viewapplicants;

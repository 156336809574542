import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

// import CampaignBrand from "../../../../../../assets/images/CampaignBrand.png";
// import CampaignBrandBackground from "../../../../../../assets/images/CampaignBrandBackground.png";

import { BASE_IMAGE_URL } from "../../../../../../utils/constants";
import { useNavigate } from "react-router-dom";

const MyCampaignsCard = ({ detail }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const CampaignBrandBackground = `${BASE_IMAGE_URL}/CampaignBrandBackground.png`;

  const handleCard = () => {
    localStorage.setItem("campaignId", detail?._id);
    navigate(`/dashboard/campaigns/view-details/${detail?._id}`);
  };

  let activities = [];
  detail?.deliverables?.forEach((deliverable) => {
    if (Array.isArray(deliverable?.activities)) {
      deliverable.activities.forEach((activity) => activities.push(activity));
    }
  });

  const uniqueActivities = [...new Set(activities)];

  return (
    <Box
      onClick={handleCard}
      sx={{
        border: `1px solid grey`,
        borderRadius: "5px",
        overflow: "hidden",
        "&:hover": {
          "#brandImg": {
            transform: "scale(1.05)",
            transition: "0.5s ease",
          },
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "calc(100% - 100px)",
            p: "15px",
            zIndex: 3,
          }}
        >
          <Typography
            fontSize="20px"
            fontWeight={theme.fonts.fontWeightBold}
            fontFamily={theme.fonts.livvic}
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              zIndex: 3,
            }}
          >
            {detail?.campaignName}
          </Typography>
          <Typography
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
            width="90%"
            height="50px"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              zIndex: 3,
            }}
          >
            {detail?.brief}
          </Typography>

          <Typography
            height="50px"
            fontSize="18px"
            fontWeight={theme.fonts.fontWeightMedium}
            fontFamily={theme.fonts.livvic}
            color={theme.colors.text.greyLight}
          >
            Compensation:-{" "}
            {detail?.totalCompensation ? (
              <Box
                component="span"
                sx={{
                  color: "red",
                }}
              >
                ${detail?.totalCompensation}
              </Box>
            ) : (
              <Box
                component="span"
                sx={{
                  color: "red",
                }}
              >
                {detail?.sharePercentage?.toFixed(2)}%{" "}
                <span style={{ fontSize: "15px" }}>of monthly revenue</span>
              </Box>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "120px",
            height: "120px",
            position: "relative",
            pt: "15px",
            pr: "15px",
          }}
        >
          <Box
            component="img"
            src={CampaignBrandBackground}
            alt="Campaign Brand Background"
            id="brandBackground"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: -2,
              width: "170px",
              height: "170px",
            }}
          ></Box>
          <Box
            component="img"
            src={detail?.brandLogo}
            alt="Campaign Brand"
            id="brandImg"
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
          ></Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          p: "0 15px 5px",
          fontSize: "18px",
          height: "60px",
          fontWeight: theme.fonts.fontWeightMedium,
          fontFamily: theme.fonts.livvic,
          color: theme.colors.text.greyLight,
        }}
      >
        Activities:-{" "}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontFamily: theme.fonts.livvic,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {uniqueActivities?.map((activity, index) => {
            const activitiesLength = uniqueActivities?.length;
            const remainingActivities = activitiesLength - 1;
            return index < 1 ? (
              <Box
                key={index}
                sx={{
                  border: "1px solid grey",
                  p: "4px 10px",
                  borderRadius: "2px",
                  fontSize: "12px",
                  maxWidth: "150px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {activity}
              </Box>
            ) : index === 1 ? (
              <Box
                key={index}
                sx={{
                  border: "1px solid grey",
                  p: "4px 10px",
                  borderRadius: "2px",
                  fontSize: "12px",
                  maxWidth: "90px",
                }}
              >
                {`+${remainingActivities} Other${
                  remainingActivities !== 1 ? "s" : ""
                }`}
              </Box>
            ) : null;
          })}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          background: "rgba(65,65,65,1)",
        }}
      >
        <Box
          sx={{
            // background: "rgba(65,65,65,1)",
            // width: "100%",
            p: "10px",
            fontFamily: theme.fonts.livvic,
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          <Box
            component="span"
            sx={{
              color: "red",
              fontWeight: "bold",
              mr: "2px",
            }}
          >
            {detail?.applications?.length}
          </Box>
          Application
        </Box>
        {/* <Box
          sx={{
            background: "rgba(61,44,44,1)",
            width: "100%",
            p: "10px",
            fontFamily: theme.fonts.livvic,
            fontSize: "14px",
            whiteSpace: "nowrap",
          }}
        >
          <Box
            component="span"
            sx={{
              color: "red",
              fontWeight: "bold",
              mr: "2px",
            }}
          >
            20{" "}
          </Box>
          AI Recommended
        </Box> */}
      </Box>
    </Box>
  );
};

export default MyCampaignsCard;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";

import Pulse from "../../../../../themes/@overrides/pulse";
import AthletesCard from "../subComponents/athletes/athletesCard";
import { dashboardAthletesDetails } from "../../../../../_mock/dummyData";
import { brandGetAthleteList } from "../../../../../services/brand/brand.service";
import { useSelector } from "react-redux";
import { async } from "q";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

const Athletes = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);

  const [dataStatus, setDataStatus] = useState(false);
  const [apiData, setApiData] = useState({
    token: localStorage.getItem("token"),
    filter: "",
    sortBy: "aiRecommended",
    sortOrder: "-1",
    page: 1,
    size: "10",
  });
  const [loading, setLoading] = useState(true);
  const value = [1, 2, 3, 4, 5, 6];

  const handleViewAll = async () => {
    navigate("/dashboard/athletes", {
      state: {
        athletesData: formData,
      },
    });
  };

  const callApi = async () => {
    const response = await brandGetAthleteList(apiData);
    setLoading(false);

    if (response.status === 200) {
      setFormData([...response?.data]);
      setDataStatus(true);
    } else {
      console.error("Failed to fetch athletes:", response?.message);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <Box
      sx={{
        // minHeight: "450px",
        paddingX: "30px",
        "@media (max-width:600px)": {
          paddingX: "10px",
        },
      }}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        // mt="115px"
        mb="20px"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
          <Pulse />
          <Typography
            color={theme.colors.common.white}
            fontFamily={theme.fonts.russoOne}
            sx={{ fontSize: { xs: "30px", md: theme.fonts.heading } }}
          >
            Athletes
          </Typography>
        </Box>
        <Typography
          fontFamily={theme.fonts.livvic}
          color={theme.colors.text.creamLight}
          sx={{ cursor: "pointer" }}
          onClick={handleViewAll}
        >
          View All
        </Typography>
      </Box>

      {loading ? (
        <Box display="flex" flexDirection="row" overflow="hidden">
          {value?.map((index) => (
            <Box
              key={index}
              ml="20px"
              sx={{ minWidth: { xs: "90%", sm: "300px" } }}
            >
              <Skeleton
                sx={{
                  bgcolor: theme.colors.text.greyDarker,
                  "&::after": { bgcolor: theme.colors.text.greyDark },
                  borderRadius: "4px",
                  width: "100%",
                }}
                variant="rectangular"
                height={220}
              />
              <Skeleton
                sx={{
                  width: "100%",
                  bgcolor: theme.colors.text.greyDarker,
                  "&::after": { bgcolor: theme.colors.text.greyDark },
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
                variant="rectangular"
                height={20}
              />
              <Skeleton
                sx={{
                  width: "100%",
                  bgcolor: theme.colors.text.greyDarker,
                  "&::after": { bgcolor: theme.colors.text.greyDark },
                  borderRadius: "4px",
                  marginTop: "10px",
                }}
                variant="rectangular"
                height={20}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Swiper
          grabCursor={true}
          navigation={true}
          modules={[Navigation]}
          initialSlide={0}
          spaceBetween={32}
          breakpoints={{
            0: { slidesPerView: 1 },
            380: { slidesPerView: 1.2 },
            440: { slidesPerView: 1.3 },
            500: { slidesPerView: 1.4 },
            560: { slidesPerView: 1.5 },
            600: { slidesPerView: 1.6 },
            680: { slidesPerView: 1.7 },
            740: { slidesPerView: 1.9 },
            800: { slidesPerView: 2 },
            860: { slidesPerView: 2.1 },
            900: { slidesPerView: 2 },
            960: { slidesPerView: 2.2 },
            1020: { slidesPerView: 2.3 },
            1080: { slidesPerView: 2.4 },
            1140: { slidesPerView: 2.5 },
            1200: { slidesPerView: 2.6 },
            1260: { slidesPerView: 2.8 },
            1320: { slidesPerView: 3 },
            1380: { slidesPerView: 3.2 },
            1420: { slidesPerView: 3.4 },
            1460: { slidesPerView: 3.6 },
            1520: { slidesPerView: 3.7 },
            1580: { slidesPerView: 3.8 },
            1640: { slidesPerView: 3.9 },
            1700: { slidesPerView: 4 },
            1760: { slidesPerView: 4.1 },
            1820: { slidesPerView: 4.2 },
            1880: { slidesPerView: 4.3 },
            1940: { slidesPerView: 4.4 },
            2000: { slidesPerView: 4.5 },
            2060: { slidesPerView: 4.6 },
            2120: { slidesPerView: 4.7 },
            2180: { slidesPerView: 4.8 },
            2240: { slidesPerView: 4.9 },
            2300: { slidesPerView: 5 },
            2360: { slidesPerView: 5.1 },
            2420: { slidesPerView: 5.2 },
            2480: { slidesPerView: 5.3 },
            2540: { slidesPerView: 5.4 },
            2600: { slidesPerView: 5.5 },
            2660: { slidesPerView: 5.6 },
            2720: { slidesPerView: 5.7 },
            2780: { slidesPerView: 5.8 },
            2840: { slidesPerView: 5.9 },
            2900: { slidesPerView: 6 },
          }}
          style={{ padding: "0 20px" }}
        >
          {formData?.slice(0, 10)?.map((detail, index) => (
            <SwiperSlide key={index}>
              <AthletesCard key={index} detail={detail} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </Box>
  );
};

export default Athletes;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import SearchIcon from "../../../../../assets/icons/SearchIcon.svg";

import ExploreBrandsListCard from "../../../../../components/afterLogin/athletes/exploreBrands/subComponents/exploreBrands.list.card";

import { dashboardCampaignDetails } from "../../../../../_mock/dummyData";
import { getBrandsList } from "../../../../../services/athlete/athlete.service";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";
import { useDebounce } from "use-debounce";
import { getBrandCount } from "../../../../../services/brand/brand.service";
import { BASE_IMAGE_URL } from "../../../../../utils/constants";

const BrandsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const BrandImage = `${BASE_IMAGE_URL}/BrandImage.png`;

  const [details, setDetails] = useState([]);
  const [brandCount, setBrandCount] = useState(0);

  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const clientSecretString = params.get("page");
  const clientSecret = parseInt(clientSecretString);

  const [apiData, setApiData] = useState({
    page: clientSecret ? clientSecret : 1,
    size: 15,
    search: "",
  });
  const [debouncedSearchTerm] = useDebounce(apiData?.search, 600);

  const filteredBrandDetails = details?.filter((detail) => {
    return detail?.brandName
      ?.toLowerCase()
      .includes(apiData?.search?.toLowerCase());
  });

  const handleBack = () => {
    navigate(-1);
  };

  const handleSearch = (e) => {
    setIsAllDataLoading(true);
    setApiData((prevFormData) => ({
      ...prevFormData,
      search: e.target.value,
    }));
  };

  const fetchBrandsList = async () => {
    setIsAllDataLoading(true);
    const { data } = await getBrandsList(apiData);
    setIsAllDataLoading(false);
    setDetails(data);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePrev = () => {
    if (apiData?.page > 1) {
      setApiData((prevFormData) => ({
        ...prevFormData,
        page: apiData?.page - 1,
      }));
    }
  };

  const handleNext = () => {
    setApiData((prevFormData) => ({
      ...prevFormData,
      page: apiData?.page + 1,
    }));
  };

  const fetchBrandCount = async () => {
    const response = await getBrandCount();
    setBrandCount(response?.data?.data);
  };

  useEffect(() => {
    window.history.replaceState(
      null,
      null,
      `/dashboard/explore-brands?page=${apiData?.page}`
    );

    fetchBrandsList();
  }, [apiData?.page]);

  useEffect(() => {
    fetchBrandsList();
    fetchBrandCount();
  }, []);

  useEffect(() => {
    if (apiData?.search !== "") {
      setApiData((prevFormData) => ({
        ...prevFormData,
        page: 1,
        search: apiData?.search,
      }));
    }
  }, [apiData?.search]);

  useEffect(() => {
    fetchBrandsList();
  }, [debouncedSearchTerm]);

  return (
    <Box
      mt={{ xs: "95px", sm: "115px" }}
      sx={{
        paddingX: { xs: "10px", md: "30px" },
      }}
    >
      {/* ----------------Top Header Icons-------------------------- */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box>
          <ArrowBackIcon
            onClick={handleBack}
            sx={{
              cursor: "pointer",
              fontSize: "50px",
              color: "white",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "relative",
            overflow: "hidden",
            borderRight: "1px solid white",
            borderLeft: "1px solid white",
            width: "100%",
            "::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "30%",
              height: "1px",
              background: "white",
              // animation: "top-bottom-boxBorderMove 8s linear infinite",
            },
            "::after": {
              content: '""',
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "30%",
              height: "1px",
              background: "white",
              // animation: "top-bottom-boxBorderMove 8s linear infinite",
            },
          }}
        >
          <Box
            sx={{
              px: "20px",
              py: "10px",
              border: " 2px solid #383838",
            }}
          >
            <Typography
              sx={{
                fontWeight: theme.fonts.fontWeightRegular,
                fontSize: { xs: "20px", md: "24px" },
                fontFamily: theme.fonts.livvic,
                color: "#A0A0A0",
              }}
            >
              Explore the brands that are excited to partner with you.{" "}
              {brandCount > 0 && (
                <>
                  <span
                    style={{
                      fontWeight: theme.fonts.fontWeightBold,
                      color: "#FFFFFF",
                    }}
                  >
                    {brandCount}+
                  </span>{" "}
                  companies in progress.
                </>
              )}
            </Typography>
          </Box>
        </Box>
        {/* )} */}
      </Box>

      <Box display="flex" justifyContent="end" mt="20px">
        <TextField
          name="search"
          placeholder="Search Brand Name"
          value={apiData?.search || ""}
          onChange={handleSearch}
          autoComplete="off"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} alt="Search Icon" />
              </InputAdornment>
            ),
            sx: {
              // border: `1px solid ${theme.colors.text.greyLightDark}`,
              background: "rgba(37,37,37,1)",
              color: theme.colors.common.white,
              borderRadius: "5px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused": {
                fieldset: {
                  borderColor: theme.colors.common.white,
                },
              },
            },
          }}
        />
      </Box>

      {/* ----------------Content Box----------------------- */}

      <Box
        sx={{
          mt: "30px",
          p: "20px",
          overflow: "hidden",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Grid container spacing={4}>
          {isAllDataLoading ? (
            Array.from({ length: 10 }, (_, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{
                  display: { xs: "flex", md: "block" },
                  justifyContent: { xs: "center" },
                }}
              >
                <ExploreBrandsListCard isAllDataLoading={isAllDataLoading} />
              </Grid>
            ))
          ) : filteredBrandDetails?.length ? (
            filteredBrandDetails?.map((detail, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{
                  display: { xs: "flex", md: "block" },
                  justifyContent: { xs: "center" },
                }}
              >
                <ExploreBrandsListCard detail={detail} key={index} />
              </Grid>
            ))
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
                fontSize: { xs: "24px", sm: "30px" },
                minHeight: "250px",
                width: "100%",
                background: "rgba(18,18,18,1)",
                borderRadius: "5px",
              }}
            >
              <Box
                component="img"
                src={BrandImage}
                alt="Brand Image"
                sx={{ width: { xs: "70px", sm: "100px" } }}
              ></Box>
              No Brands Yet!
            </Box>
          )}
        </Grid>
      </Box>
      <Box
        sx={{
          gap: "20px",
          mb: "30px",
          pb: "30px",
          px: "20px",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: theme.colors.dashboard.grey200,
        }}
      >
        <Button
          onClick={handlePrev}
          disabled={apiData?.page === 1}
          sx={{
            px: { xs: "50px", md: "70px" },
            textTransform: "none",
            color: "white",
            fontSize: { xs: "18px", md: "20px" },
            borderRadius: "5px",
            backgroundColor: "#DD0000",
            marginTop: "10px",
            fontWeight: 500,
            "&.MuiButton-root.Mui-disabled": {
              background: "#252525",
              color: "#c5c5c5",
            },
            "&:hover": {
              backgroundColor: "red",
            },
          }}
        >
          Previous
        </Button>
        <Typography sx={{ mt: "5px", fontSize: "20px", fontWeight: 600 }}>
          {apiData?.page}
        </Typography>
        <Button
          onClick={handleNext}
          disabled={filteredBrandDetails?.length < 10}
          sx={{
            px: { xs: "50px", md: "80px" },
            textTransform: "none",
            color: "white",
            fontSize: { xs: "18px", md: "20px" },
            borderRadius: "5px",
            backgroundColor: "#DD0000",
            marginTop: "10px",
            fontWeight: 500,
            "&.MuiButton-root.Mui-disabled": {
              background: "#252525",
              color: "#c5c5c5",
            },
            "&:hover": {
              backgroundColor: "red",
            },
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default BrandsList;

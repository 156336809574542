import {
  Grid,
  Typography,
  Box,
  useTheme,
  TextField,
  InputAdornment,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import {
  campaignAddRevenue,
  removeProjectFiles,
} from "../../../../../../services/campaign/campaign.service";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import MonthlyRevenueModal from "./monthlyRevenue.modal";
import {
  DateCalendar,
  DatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import RevenuePaymentModal from "./revenuePayment.modal";
import { getClickCount } from "../../../../../../services/product/product.service";
import DeleteIcon from "../../../../../../assets/icons/Delete.svg";
import TaskNotCompletedIcon from "../../../../../../assets/icons/TaskNotCompletedicon.svg";

const ViewActivitydetailsCard = ({
  details,
  callApi,
  paymentModel,
  status,
}) => {
  const theme = useTheme();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isPaymentOpen, setPaymentOpen] = useState(false);
  const [revenueId, setRevenueId] = useState(null);
  const [formData, setFormData] = useState({
    deliverableId: "",
    date: "",
    amount: "",
  });
  const [clickCountData, setClickCountData] = useState(null);

  const handlePatmentClose = () => {
    setPaymentOpen(false);
    setFormData({
      ...formData,
      date: "",
      deliverableId: "",
      amount: "",
    });
    toast.success("Revenue Updated");
    callApi();
  };

  const handlePatmentOpen = () => {
    setPaymentOpen(true);
  };

  const handleCalenderOpen = () => {
    setCalendarOpen(true);
  };

  const handleCalenderClose = () => {
    setCalendarOpen(false);
  };

  const handleDateSelect = (selectedDate) => {
    const formattedDate = dayjs(selectedDate).format("MM-DD-YYYY");

    setFormData({
      ...formData,
      date: formattedDate,
      deliverableId: details?._id,
    });
    setCalendarOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDelete = async (index) => {
    const clickedFile = details?.additionalProjectFiles[index];

    const response = await removeProjectFiles(details?._id, clickedFile);
    if (response.status === 200) {
      toast.success("Deleted successfully");
      callApi();
    }
  };

  function getMonth(dateString) {
    const date = new Date(dateString);
    return date.getMonth() + 1;
  }

  console.log("============", details?.revenues);
  const handleAdd = async () => {
    const trimmedAmount = formData?.amount?.toString().trim();

    if (trimmedAmount === "") {
      toast.error("Enter amount");
      return;
    } else if (!formData?.date) {
      toast.error("Enter month");
      return;
    } else if (
      details?.revenues?.some(
        (rev) => getMonth(rev?.date) === getMonth(formData?.date)
      )
    ) {
      toast.error("Revenue for this month already exists");
      return;
    }

    setFormData({
      ...formData,
      amount: parseFloat(formData?.amount),
    });

    try {
      const response = await campaignAddRevenue(formData);
      if (response.status === 200) {
        if (paymentModel === "Revenue Share") {
          setRevenueId(response.data._id);
          handlePatmentOpen();
        } else {
          setFormData({
            ...formData,
            date: "",
            deliverableId: "",
            amount: "",
          });
          toast.success("Revenue Updated");
          callApi();
        }
        // window.location.reload();
      } else {
        toast.error(response?.message || response?.status);
      }
    } catch (error) {
      toast.error(error?.message || error?.status);
    }
  };

  useEffect(() => {
    callClickCountApi();
  }, []);

  const callClickCountApi = async () => {
    try {
      const response = await getClickCount(details?._id);

      if (response.status === 200) {
        setClickCountData(response.data);
      } else {
        console.error(
          "Failed to fetch getClickCount details:",
          response?.message
        );
      }
    } catch (error) {
      console.error("Error fetching getClickCount details:", error);
    }
  };

  const handleAddProjectFile = (data) => {
    if (data) {
      window.open(data, "_blank");
    } else {
      console.error("Additional peoject file link is not available");
    }
  };

  const handleServiceUrl = (data) => {
    if (data) {
      if (!/^https?:\/\//i.test(data)) {
        data = "https://" + data;
      }
      window.open(data, "_blank");
    } else {
      console.error("Product link is not available");
    }
  };

  const isRevenuesForThisMonth = details?.revenues?.some((revenue) => {
    const currentDate = dayjs();
    const revenueDate = dayjs(revenue?.date);
    return currentDate.isSame(revenueDate, "month");
  });

  return (
    <Box
      sx={{
        width: { xs: "90%", md: "45%" },
        my: "20px",
        mx: "auto",
        border: `1px solid grey`,
        background: theme.colors.common.black,
        overflow: "hidden",
      }}
    >
      <Box p="5px 15px 15px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize="20px"
            fontFamily={theme.fonts.livvic}
            sx={{
              color: "white",
            }}
          >
            {details?.deliverableName || ""}
          </Typography>
        </Box>
        {details?.deliverableName && <Divider color="white" />}
        <Typography
          my="10px"
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            gap: 0.5,
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: { xs: "14px", sm: "18px" },
          }}
        >
          Activities
          <Box
            component="span"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "white",
              width: "calc(100% - 130px)",
              textAlign: "right",
            }}
          >
            {details?.activities}
          </Box>
        </Typography>

        <Typography
          mt="10px"
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: { xs: "14px", sm: "18px" },
          }}
        >
          Compensation
          <Box
            component="span"
            sx={{
              color: "red",
            }}
          >
            {paymentModel === "Revenue Share" ? (
              <>
                {details?.compensation}%
                <span style={{ fontSize: "15px" }}> of monthly revenue</span>
              </>
            ) : (
              `$${details?.compensation}`
            )}
          </Box>
        </Typography>
        {/* <Typography
          mt="10px"
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            fontSize: { xs: "14px", sm: "18px" },
          }}
        >
          Product/Service URL
          <Box
            color="#FF0101"
            component="span"
            fontWeight={theme.fonts.fontWeightRegular}
            sx={{
              cursor: "pointer",
              width: "100%",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            onClick={() => handleServiceUrl(details?.deliverableUrl)}
          >
            {details?.deliverableUrl}
          </Box>
        </Typography> */}
        <Typography
          my="10px"
          fontWeight={theme.fonts.fontWeightMedium}
          fontFamily={theme.fonts.livvic}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: { xs: "14px", sm: "18px" },
          }}
        >
          Social Proof
        </Typography>
        <Box
          height="100px"
          paddingBottom="10px"
          paddingRight="5px"
          sx={{
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
              borderRadius: "4px",
            },
          }}
        >
          {details?.additionalProjectFiles?.length > 0 ? (
            details?.additionalProjectFiles?.map((detail, index) => (
              <Box
                key={index}
                display="flex"
                backgroundColor="#0E0E0E"
                border="1px solid #797979"
                alignItems="center"
                borderRadius="5px"
                my="5px"
                p="5px"
                width="100%"
              >
                <Box
                  backgroundColor="#5e5d5d"
                  borderRadius="5px"
                  p="1px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleAddProjectFile(detail)}
                >
                  <InsertDriveFileOutlinedIcon sx={{ marginTop: "3px" }} />
                </Box>
                <Typography
                  sx={{
                    cursor: "pointer",
                    width: "calc(100% - 40px)",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    mx: "5px",
                  }}
                  onClick={() => handleAddProjectFile(detail)}
                >
                  {detail?.split("/").pop()}
                </Typography>
                {status === "pending" && (
                  <Box>
                    <img
                      onClick={() => handleDelete(index)}
                      src={DeleteIcon}
                      alt="Delete icon"
                      style={{ cursor: "pointer" }}
                    />
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <Typography
              mt="12px"
              ml="10px"
              color="#C5C5C5"
              sx={{ fontStyle: "italic" }}
            >
              No Social Proof
            </Typography>
          )}
        </Box>
        {clickCountData?.length > 0 ? (
          <Box
            // key={data}
            mt="10px"
            backgroundColor="#252525"
            paddingX="10px"
            paddingY="5px"
          >
            <Typography
              my="10px"
              fontSize="18px"
              fontWeight={theme.fonts.fontWeightMedium}
              fontFamily={theme.fonts.livvic}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width:468px)": {
                  fontSize: "14px",
                },
              }}
            >
              Total clicks
              <Box component="span" fontWeight={theme.fonts.fontWeightMedium}>
                {clickCountData[0]?.totalCount}
              </Box>
            </Typography>
            <Typography
              my="10px"
              fontSize="18px"
              fontWeight={theme.fonts.fontWeightMedium}
              fontFamily={theme.fonts.livvic}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "@media (max-width:468px)": {
                  fontSize: "14px",
                },
              }}
            >
              Unique clicks
              <Box component="span" fontWeight={theme.fonts.fontWeightMedium}>
                {clickCountData[0]?.uniqueCount}
              </Box>
            </Typography>
            <Typography
              fontWeight={theme.fonts.fontWeightMedium}
              fontFamily={theme.fonts.livvic}
              sx={{ fontSize: { xs: "14px", md: "18px" } }}
            >
              Revenue
              {formData?.date && (
                <span style={{ color: "#AFACAC" }}>
                  ({dayjs(formData?.date).locale("en").format("MMMM YYYY")})
                </span>
              )}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <TextField
                name="revenue"
                placeholder="Amount"
                autoComplete="off"
                value={
                  formData?.amount ||
                  (details?.revenues &&
                  details?.revenues.some(
                    (revenue) =>
                      dayjs(revenue?.date).format("MM-YYYY") ===
                      dayjs().format("MM-YYYY")
                  )
                    ? details?.revenues.find(
                        (revenue) =>
                          dayjs(revenue?.date).format("MM-YYYY") ===
                          dayjs().format("MM-YYYY")
                      ).amount
                    : "")
                }
                onChange={(e) =>
                  setFormData({ ...formData, amount: e.target.value })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        color: theme.colors.common.white,
                      }}
                    >
                      <Typography
                        sx={{
                          borderRight: `1px solid ${theme.colors.text.greyLight}`,
                          pr: "8px",
                          fontSize: "18px",
                          fontWeight: theme.fonts.fontWeightMedium,
                        }}
                      >
                        $
                      </Typography>
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.text.greyLight}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  width: "75%",
                  mt: "5px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                }}
              />
              {isRevenuesForThisMonth ? (
                <Box
                  sx={{
                    backgroundColor: "#FF0101",
                    padding: "14px",
                    borderRadius: "5px",
                    mt: "5px",
                    cursor: "not-allowed",
                    opacity: 0.5,
                  }}
                >
                  <CalendarTodayOutlinedIcon sx={{ fontSize: "25px" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "#FF0101",
                    padding: "14px",
                    borderRadius: "5px",
                    mt: "5px",
                  }}
                >
                  <CalendarTodayOutlinedIcon
                    onClick={handleCalenderOpen}
                    sx={{ cursor: "pointer" }}
                  />
                  <Dialog open={isCalendarOpen} onClose={handleCalenderClose}>
                    <DialogContent
                      sx={{
                        backgroundColor: theme.colors.background.formInput,
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar
                          onChange={handleDateSelect}
                          disableFuture
                          views={["month", "year"]}
                          // shouldDisableDate={shouldDisableDate}
                          sx={{
                            "& .MuiPickersDay-root": {
                              color: theme.colors.common.white,
                            },
                            "& .MuiSvgIcon-root": {
                              color: theme.colors.common.white,
                            },
                            "& .MuiTypography-caption": {
                              color: theme.colors.common.white,
                            },
                            color: theme.colors.text.greyLight,
                            backgroundColor: theme.colors.background.formInput,
                          }}
                        />
                      </LocalizationProvider>
                    </DialogContent>
                  </Dialog>
                </Box>
              )}
              {isRevenuesForThisMonth ? (
                <Box
                  sx={{
                    backgroundColor: "#FF0101",
                    padding: "14px",
                    borderRadius: "5px",
                    mt: "5px",
                    cursor: "not-allowed",
                    opacity: 0.5,
                  }}
                >
                  <DoneOutlinedIcon sx={{ fontSize: "25px" }} />
                </Box>
              ) : (
                <Box
                  sx={{
                    backgroundColor: "#FF0101",
                    padding: "13px",
                    borderRadius: "5px",
                    mt: "5px",
                  }}
                >
                  <DoneOutlinedIcon
                    onClick={handleAdd}
                    sx={{ fontSize: "25px", cursor: "pointer" }}
                  />
                </Box>
              )}
            </Box>
            <Typography
              onClick={handleDialogOpen}
              mt="10px"
              fontWeight={theme.fonts.fontWeightRegular}
              fontFamily={theme.fonts.livvic}
              sx={{
                fontSize: { xs: "12px", md: "16px" },
                color: "#7F8CFE",
                cursor: "pointer",
              }}
            >
              Manage monthly revenue
            </Typography>
          </Box>
        ) : (
          <Box
            mt="10px"
            backgroundColor="#252525"
            paddingX="10px"
            paddingY="5px"
          >
            <Box
              py="35px"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box
                component="img"
                src={TaskNotCompletedIcon}
                alt="Task not completed Icon"
                sx={{
                  width: "100px",
                }}
              ></Box>
              <Typography
                mt="14px"
                fontWeight={500}
                fontFamily={theme.fonts.livvic}
                color="#C5C5C5"
              >
                Task Not Completed
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            minWidth: "50vw",
            minHeight: "50%",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <MonthlyRevenueModal
          details={details}
          handleDialogClose={handleDialogClose}
          callApi={callApi}
          paymentModel={paymentModel}
          deliverableId={details?._id}
        />
      </Dialog>
      <Dialog
        open={isPaymentOpen}
        onClose={handlePatmentClose}
        PaperProps={{
          sx: {
            minWidth: "50vw",
            minHeight: "50%",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <RevenuePaymentModal
          amount={formData.amount}
          handlePatmentClose={handlePatmentClose}
          deliverableId={details?._id}
          revenueId={revenueId}
        />
      </Dialog>
    </Box>
  );
};

export default ViewActivitydetailsCard;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import BurrstLogoIcon from "../../../../../assets/icons/BurrstLogoIcon.svg";
import School from "../../../../../assets/icons/School.svg";
import CloseIcon from "@mui/icons-material/Close";
import RightArrow from "../../../../../assets/images/RightArrow.svg";
import DownloadIcon from "../../../../../assets/icons/DownloadIcon.svg";
import AcceptIcon from "../../../../../assets/icons/AcceptIcon.svg";
import DeclineIcon from "../../../../../assets/icons//DeclineIcon.svg";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DoneIcon from "@mui/icons-material/Done";
import AcceptSelectedAthleteUpdateModal from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/acceptSelectedAthleteUpdate.modal";
import DeclineSelectedAthleteUpdateModal from "../../../../../components/afterLogin/brands/home/subComponents/myCampaigns/declineSelectedAthleteUpdate.modal";
import DefaultImg from "../../../../../assets/images/DefaultAvatar.jpg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { respondToProof } from "../../../../../services/campaign/campaign.service";
import {
  ContentCopyRounded,
  LibraryAddCheckRounded,
} from "@mui/icons-material";
import { getTrackLink } from "../../../../../services/product/product.service";
import { toast } from "react-toastify";

const CampaignSignedAthleteUpdatePage = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isXs = window.innerWidth <= 430;
  const [openAcceptDialog, setOpenAcceptDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);
  const detail = location.state.detail;
  const campaignid = localStorage.getItem("campaignId");
  const [responseData, setResponseData] = useState({
    campaignId: campaignid,
    athleteId: detail?.athleteId,
    deliverableId: "",
    accept: null,
    rejectionReason: "",
  });
  const paymentModel = detail?.paymentModel;

  const handleBack = () => {
    navigate(-1);
  };
  // const [trackLinkData, setTrackLinkData] = useState(null);

  // const [formData, setFormData] = useState({
  //   deliverableId: "",
  //   athleteId: detail?.athleteId,
  //   url: "",
  // });

  useEffect(() => {
    detail?.proofs?.map(async (data, index) => {
      const linkData = await callTrackLinkApi({
        deliverableId: data?.deliverable[0]?._id,
        athleteId: detail?.athleteId,
        url: data?.deliverable[0]?.deliverableUrl,
      });

      data.link = linkData;
    });
  }, []);

  const callTrackLinkApi = async (data) => {
    let responseData = null;
    try {
      const response = await getTrackLink(data);

      if (response.status === 200) {
        // setTrackLinkData(response.data);
        responseData = response.data;
      } else {
        console.error(
          "Failed to fetch getTrackLink details:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching getTrackLink details:", error);
    }
    return responseData;
  };

  const [deliverableId, setDeliverableId] = useState("");

  const handleAcceptDialogOpen = (value) => {
    // setResponseData((prevSelections) => ({
    //   ...prevSelections,
    //   deliverableId: value,
    // }));
    setDeliverableId(value);
    setOpenAcceptDialog(true);
  };

  const handleDeclineDialogOpen = (value) => {
    // setResponseData((prevSelections) => ({
    //   ...prevSelections,
    //   deliverableId: value,
    // }));
    setDeliverableId(value);
    setOpenDeclineDialog(true);
  };

  const handleAcceptDialogClose = () => {
    setOpenAcceptDialog(false);
  };

  const handleDeclineDialogClose = () => {
    setOpenDeclineDialog(false);
  };

  const callApi = async (data) => {
    const response = await respondToProof(data);

    if (response?.status === 200) {
      toast.success("Respond sent successfully!");
      navigate(-1);
    } else if (
      response?.status === 500 &&
      response?.error?.raw?.code === "balance_insufficient"
    ) {
      toast.error("Balance Insufficient");
    } else {
      toast.error("Failed to sent respond");
      console.error("Failed respond to proof:", response.message);
      navigate("/dashboard/campaigns/selected-athlete");
    }
  };

  const handleDownloadProof = (file) => {
    if (file) {
      window.open(file, "_blank");
    } else {
      console.error("Proof link is not available");
    }
  };

  const handleContract = () => {
    if (detail?.contract) {
      window.open(detail?.contract, "_blank");
    } else {
      console.error("Contract link is not available");
    }
  };

  const handleAddProjectFile = (data) => {
    if (data) {
      window.open(data, "_blank");
    } else {
      console.error("Additional peoject file link is not available");
    }
  };

  // const link = "www.googlepixel.com";
  const [isCopied, setIsCopied] = useState(false);

  const handleClickCopy = (link) => {
    navigator.clipboard.writeText(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 1500);
  };

  return (
    <Box
      sx={{
        mt: { xs: "95px", sm: "115px" },
        px: { xs: "10px", sm: "30px" },
      }}
    >
      {/* Arrow Icon */}
      <Box my="20px">
        <ArrowBackIcon
          onClick={handleBack}
          sx={{
            cursor: "pointer",
            fontSize: { xs: "25px", sm: "40px" },
            color: "white",
          }}
        />
      </Box>

      {/* Details Container*/}
      <Box
        sx={{
          background: "#121212",
          p: { xs: "15px", sm: "30px" },
          mb: "30px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 2,
            mb: "30px",
          }}
        >
          <Box
            sx={{
              width: { xs: "90px", md: "130px" },
              height: { xs: "90px", md: "120px" },
              position: "relative",
            }}
          >
            <Box
              component="img"
              src={detail?.athlete?.profile?.profileImageLink || DefaultImg}
              alt="Avatar"
              id="avatar"
              sx={{ width: "100%", height: "100%" }}
              onError={(e) => (e.target.src = DefaultImg)}
            ></Box>
            <Box
              component="img"
              src={BurrstLogoIcon}
              alt="Burrst Logo Icon"
              sx={{
                position: "absolute",
                top: "-30px",
                right: "-30px",
              }}
              id="Burrst Logo Icon"
            ></Box>
          </Box>
          <Box sx={{ ml: { md: "15px" } }}>
            <Typography
              fontFamily={theme.fonts.livvic}
              fontWeight={theme.fonts.fontWeightMedium}
              fontSize="32px"
              sx={{
                mt: { xs: 0, md: 2 },
                fontSize: { xs: "22px", md: "24px" },
              }}
            >
              {detail?.athlete?.firstName || ""}{" "}
              {detail?.athlete?.lastName || ""}
            </Typography>
            <Box display="flex" gap={1} my={1}>
              <Box
                component="img"
                src={School}
                alt={`School ${location?.detail?.id}`}
              ></Box>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontSize: { xs: "18px", md: "20px" },
                }}
              >
                School: {detail?.athlete?.profile?.school || ""}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          onClick={handleContract}
          width="100%"
          padding="5px"
          display="flex"
          justifyContent="space-between"
          border="1px solid #797979"
          borderRadius="5px"
          backgroundColor={theme.colors.background.formInput}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            sx={{
              color: "white",
              fontSize: { xs: "15px", md: "18px" },
              fontWeight: theme.fonts.fontWeightRegular,
              fontFamily: theme.fonts.livvic,
            }}
          >
            Details of the contract
          </Typography>
          <img
            src={RightArrow}
            alt="rightArrow"
            style={{
              width: "15px",
              marginRight: "5px",
            }}
          />
        </Box>
        {detail?.proofs?.map((data, index) => (
          <Box key={index}>
            {data?.deliverable?.map((value, deliverableIndex) => (
              <Box
                key={deliverableIndex}
                mt="30px"
                borderRadius="5px"
                backgroundColor="#121212"
                border="2px solid #797979"
                padding="15px"
                overflow="hidden"
              >
                <Typography
                  mb="12px"
                  color="#C5C5C5"
                  fontWeight={theme.fonts.fontWeightRegular}
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    fontSize: { xs: "16px", sm: "20px" },
                  }}
                >
                  Activities
                  <Box
                    component="span"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "white",
                      // width: "calc(100% - 150px)",
                      // textAlign: "right",
                    }}
                  >
                    {value?.activities?.join(", ")}
                  </Box>
                </Typography>
                {data?.link && (
                  <Typography
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 1,
                      flexWrap: "wrap",
                      fontFamily: theme.fonts.livvic,
                      fontSize: { xs: "16px", sm: "20px" },
                      fontWeight: theme.fonts.fontWeightRegular,
                      color: "#C5C5C5",
                      mb: "12px",
                    }}
                  >
                    Tracking link
                    <Box
                      component="span"
                      sx={{
                        py: "3px",
                        px: "8px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#252525",
                        borderRadius: "4px",
                        width: "100%",
                        maxWidth: "250px",
                      }}
                    >
                      <Typography
                        color="white"
                        backgroundColor="#252525"
                        fontWeight={theme.fonts.fontWeightRegular}
                        sx={{
                          width: "calc(100% - 20px)",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data?.link}
                      </Typography>

                      {isCopied ? (
                        <LibraryAddCheckRounded
                          sx={{
                            width: "20px",
                          }}
                        />
                      ) : (
                        <ContentCopyRounded
                          sx={{
                            cursor: "pointer",
                            width: "20px",
                          }}
                          onClick={() => handleClickCopy(data?.link)}
                        />
                      )}
                    </Box>
                  </Typography>
                )}
                <Typography
                  mt="12px"
                  fontSize="20px"
                  color="#C5C5C5"
                  fontWeight={theme.fonts.fontWeightRegular}
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    "@media (max-width:600px)": {
                      fontSize: "16px",
                    },
                  }}
                >
                  Compensation
                  {/* <Box
                            color="#FF0101"
                            component="span"
                            fontWeight={theme.fonts.fontWeightRegular}
                          >
                            ${value?.compensation}
                          </Box> */}
                  {paymentModel === "Revenue Share" ? (
                    <Box component="span" color="red">
                      {value?.compensation}%{" "}
                      <span
                        style={{
                          fontWeight: 400,
                          fontSize: "16px",
                        }}
                      >
                        of monthly revenue
                      </span>
                    </Box>
                  ) : (
                    <Box component="span" color="red">
                      ${value?.compensation}
                    </Box>
                  )}
                </Typography>

                {value?.additionalProjectFiles?.length > 0 && (
                  <Box mt="20px">
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "20px" },
                        fontWeight: 500,
                      }}
                    >
                      Social Proof
                    </Typography>

                    {value?.additionalProjectFiles?.map((data, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          maxWidth: "500px",
                          p: "5px",
                          my: "5px",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#0E0E0E",
                          borderRadius: "5px",
                          border: "1px solid #797979",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "calc(100% - 20px)",
                            }}
                          >
                            <Box
                              backgroundColor="#5e5d5d"
                              borderRadius="5px"
                              p="1px"
                              mr="10px"
                            >
                              <InsertDriveFileOutlinedIcon
                                sx={{ marginTop: "3px", cursor: "pointer" }}
                              />
                            </Box>
                            <Typography
                              sx={{
                                color: "#C5C5C5",
                                fontSize: { xs: "12px", md: "14px" },
                                fontWeight: theme.fonts.fontWeightRegular,
                                fontFamily: theme.fonts.livvic,
                                width: "calc(100% - 20px)",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {data || ""}
                            </Typography>
                          </Box>

                          <Box
                            onClick={() => handleDownloadProof(data)}
                            component="img"
                            src={DownloadIcon}
                            alt="Avatar"
                            id="avatar"
                            sx={{
                              width: "20px",
                              cursor: "pointer",
                            }}
                          ></Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
                {data?.submitionStatus && (
                  <Box mt="14px">
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: 500,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        mt: "5px",
                        color: "#D9D9D9",
                        fontSize: { xs: "14px", md: "16px" },
                        fontWeight: 400,
                        fontFamily: theme.fonts.livvic,
                        maxHeight: "150px",
                        overflowY: "scroll",
                        "&::-webkit-scrollbar": {
                          width: "4px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: "transparent",
                          borderRadius: "4px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#777777",
                          borderRadius: "4px",
                        },
                      }}
                    >
                      {data?.description || "No description provided"}
                    </Typography>
                  </Box>
                )}
                {data?.submitionStatus && (
                  <Box
                    mt="15px"
                    display="flex"
                    flexDirection="column"
                    flexWrap="wrap"
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: 500,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      Uploaded Proof
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "500px",
                        p: "5px",
                        my: "5px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#0E0E0E",
                        borderRadius: "5px",
                        border: "1px solid #797979",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "calc(100% - 20px)",
                          }}
                        >
                          <Box
                            backgroundColor="#5e5d5d"
                            borderRadius="5px"
                            p="1px"
                            mr="10px"
                          >
                            <InsertDriveFileOutlinedIcon
                              sx={{ marginTop: "3px", cursor: "pointer" }}
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: "#C5C5C5",
                              fontSize: { xs: "12px", md: "14px" },
                              fontWeight: theme.fonts.fontWeightRegular,
                              fontFamily: theme.fonts.livvic,
                              width: "calc(100% - 20px)",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {data?.proofFile || ""}
                          </Typography>
                        </Box>

                        <Box
                          onClick={() => handleDownloadProof(data?.proofFile)}
                          component="img"
                          src={DownloadIcon}
                          alt="Avatar"
                          id="avatar"
                          sx={{
                            width: "20px",
                            cursor: "pointer",
                          }}
                        ></Box>
                      </Box>
                    </Box>
                  </Box>
                )}
                {data?.submitionStatus && (
                  <Box
                    mt="15px"
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="column"
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: { xs: "18px", md: "20px" },
                        fontWeight: 500,
                        fontFamily: theme.fonts.livvic,
                      }}
                    >
                      URL
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        p: "5px",
                        my: "5px",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#0E0E0E",
                        borderRadius: "5px",
                        border: "1px solid #797979",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#C5C5C5",
                          fontSize: { xs: "14px", md: "16px" },
                          fontWeight: theme.fonts.fontWeightRegular,
                          fontFamily: theme.fonts.livvic,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {data?.url || "No URL provided"}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {!data?.brandResponse && data?.submitionStatus && (
                  <Box
                    mt="15px"
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    gap={1}
                  >
                    <Button
                      onClick={() => handleDeclineDialogOpen(value?._id)}
                      sx={{
                        width: { xs: "90%", md: "45%" },
                        background: "#FF0101",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "darkred",
                        },
                        fontFamily: theme.fonts.montserrat,
                        fontWeight: theme.fonts.fontWeightBold,
                        textTransform: "none",
                        fontSize: { xs: "16px", md: "20px" },
                      }}
                    >
                      <Box
                        component="img"
                        src={DeclineIcon}
                        alt="declineIcon"
                        sx={{
                          width: { xs: "12px", md: "18px" },
                          mr: "15px",
                        }}
                      ></Box>
                      {"  "}
                      DECLINE
                    </Button>
                    <Button
                      onClick={() => handleAcceptDialogOpen(value?._id)}
                      sx={{
                        width: { xs: "90%", md: "45%" },
                        color: "white",
                        background: "#23AC00",
                        "&:hover": {
                          backgroundColor: "darkgreen",
                        },
                        fontFamily: theme.fonts.montserrat,
                        fontWeight: theme.fonts.fontWeightBold,
                        textTransform: "none",
                        fontSize: { xs: "16px", md: "20px" },
                      }}
                    >
                      <Box
                        component="img"
                        src={AcceptIcon}
                        alt="acceptIcon"
                        sx={{
                          width: { xs: "18px", md: "26px" },
                          mr: "15px",
                        }}
                      ></Box>{" "}
                      ACCEPT
                    </Button>
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        ))}

        <Dialog
          open={openAcceptDialog}
          //onClose={handleAcceptDialogClose}
          sx={{
            backgroundColor: "rgb(255,255,255,0.2)",
            "& .MuiDialog-paper": {
              width: { xs: "80%", md: "70%", lg: "50%" },
              maxWidth: "unset",
              height: "500px",
              maxHeight: "unset",
            },
          }}
        >
          <AcceptSelectedAthleteUpdateModal
            handleAcceptDialogClose={handleAcceptDialogClose}
            campaignId={campaignid}
            athleteId={detail?.athleteId}
            deliverableId={deliverableId}
            callApi={callApi}
            navigate={navigate}
          />
        </Dialog>
        <Dialog
          open={openDeclineDialog}
          //onClose={handleDeclineDialogClose}
          sx={{
            backgroundColor: "rgb(255,255,255,0.2)",
            "& .MuiDialog-paper": {
              width: { xs: "80%", md: "70%", lg: "50%" },
              maxWidth: "unset",
              height: "500px",
              maxHeight: "unset",
            },
          }}
        >
          <DeclineSelectedAthleteUpdateModal
            handleDeclineDialogClose={handleDeclineDialogClose}
            campaignId={campaignid}
            athleteId={detail?.athleteId}
            deliverableId={deliverableId}
            callApi={callApi}
            navigate={navigate}
          />
        </Dialog>
      </Box>
    </Box>
  );
};

export default CampaignSignedAthleteUpdatePage;

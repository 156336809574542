import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import InputEmailIcon from "../../assets/icons/InputEmailIcon.svg";
import InputPasswordLockIcon from "../../assets/icons/InputPasswordLockIcon.svg";

import LoginBackground from "../../assets/images/BackgrounImage.png";
import Schema from "../../joi.schema/auth/login.Schema";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PADDING_X, BASE_IMAGE_URL, HOST_URL } from "../../utils/constants";
import { authLogIn } from "../../services/auth/auth.service";
import { athleteRegistrationStage } from "../../services/athlete/athlete.service";
import { useDispatch } from "react-redux";
import { login } from "../../store/authSlice";
import { brandSubscribedCheck } from "../../services/brand/brand.service";
import GracePeriodExpiredModal from "../../components/auth/subComponent/gracePeriodExpired.modal";

const Login = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  //const LoginBackground = `${BASE_IMAGE_URL}/loginBackground.svg`;
  const [brandSubscribedChecked, setBrandSubscribedChecked] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleBurrstLogo = () => {
    navigate("/");
  };
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const gracePeriod = localStorage.getItem("gracePeriod");
    if (token && gracePeriod !== "undefined") {
      navigate("/dashboard");
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);

  const handleRegistration = () => {
    navigate("/user-type-selection");
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleBack = () => {
    navigate("/");
  };

  const handleLogin = async () => {
    const { error } = Schema.validate(formData);

    if (error) {
      error?.details?.forEach((errors) => {
        toast.error(errors.message);
      });
    } else {
      try {
        setLoading(true);
        const response = await authLogIn(formData);
        if (response?.status === 200) {
          setLoading(false);
          dispatch(
            login({
              userData: {
                token: response.data.token,
                usertype: response.data.usertype,
                userId: response.data.userId,
                plan: response.data.plan,
                isBrandAdmin: response.data.isBrandAdmin,
              },
            })
          );
          let subscriptionResponse = {};
          if (response.data.usertype === "BRAND") {
            subscriptionResponse = await brandSubscribedCheck();
            setBrandSubscribedChecked(subscriptionResponse?.data);
            localStorage.setItem(
              "gracePeriod",
              subscriptionResponse?.data?.gracePeriod
            );
          }

          if (response?.data?.usertype === "ATHLETE") {
            navigate("/dashboard");
          } else if (
            response?.data?.usertype === "BRAND" &&
            subscriptionResponse?.data
          ) {
            if (
              !subscriptionResponse?.data?.subscription &&
              !subscriptionResponse?.data?.trialStarted &&
              !subscriptionResponse?.data?.gracePeriod
            ) {
              navigate("/subscription");
            } else if (
              subscriptionResponse?.data?.subscription &&
              subscriptionResponse?.data?.trialStarted &&
              !subscriptionResponse?.data?.gracePeriod
            ) {
              navigate("/dashboard");
            } else if (
              !subscriptionResponse?.data?.subscription &&
              subscriptionResponse?.data?.trialStarted &&
              subscriptionResponse?.data?.gracePeriod
            ) {
              navigate("/dashboard");
            } else if (
              !subscriptionResponse?.data?.subscription &&
              subscriptionResponse?.data?.trialStarted &&
              !subscriptionResponse?.data?.gracePeriod
            ) {
              localStorage.clear();
              setShowDialog(true);
            }
          }
        } else if (
          response.status === 301 &&
          response.message === "profile is incomplete"
        ) {
          setLoading(false);
          if (response.data.usertype === "ATHLETE") {
            window.localStorage.setItem(
              "registrationToken",
              response.data.registrationToken
            );
            const stageResponse = await athleteRegistrationStage(
              response.data.registrationToken
            );

            if (stageResponse.status === 200) {
              if (stageResponse.data.stage === 1) {
                navigate("/athletes/otp-verification", {
                  state: {
                    userId: response.data.userId,
                    registrationToken: response.data.registrationToken,
                    countryCode: stageResponse.data.user.countryCode,
                    phoneNo: stageResponse.data.user.phoneNo,
                  },
                });
              } else if (stageResponse.data.stage === 2) {
                navigate("/athletes/profile", {
                  state: {
                    userId: response.data.userId,
                    registrationToken: response.data.registrationToken,
                  },
                });
              } else if (stageResponse.data.stage === 3) {
                navigate("/athletes/public-profile", {
                  state: {
                    userId: response.data.userId,
                    registrationToken: response.data.registrationToken,
                  },
                });
              }
            } else {
              console.log("Error");
            }
          } else if (response.data.usertype === "BRAND") {
            window.localStorage.setItem(
              "registrationToken",
              response.data.registrationToken
            );
            navigate("/brands/public-profile", {
              state: {
                usertype: response.data.usertype,
                registrationToken: response.data.registrationToken,
              },
            });
          }
        } else if (
          response.status === 302 &&
          response.message ===
            "you need to change your password before you could login"
        ) {
          setLoading(false);
          navigate("/reset-password", {
            state: {
              email: formData.email,
            },
          });
        } else {
          setLoading(false);
          toast.error(response.message);
          console.error("Login failed:", response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.status);
        console.error("Error during login:", error.message);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Login | Burrst</title>
        <meta name="description" content="Login to view your account." />
        <link rel="canonical" href={`${HOST_URL}/login`} />
      </Helmet>

      <Box>
        <Box
          component="img"
          src={LoginBackground}
          alt="Login Background"
          sx={{
            width: "100vw",
            height: "100vh",
            objectFit: "cover",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          sx={{
            //background: `url(${LoginBackground}) no-repeat`,
            //backgroundSize: "contain",
            mt: "20px",
            mb: "40px",
            paddingX: { sx: "10px", md: PADDING_X },
          }}
        >
          <Box
            sx={{
              position: "fixed",
              left: "20px",
              top: "20px",
              width: "35px",
              height: "35px",
              cursor: "pointer",
              backgroundColor: theme.colors.common.black,
              color: theme.colors.text.greyLight,
              borderRadius: "5px",
              boxShadow: "0px 0px 7px 0px rgba(197, 197, 197, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={handleBack}
          >
            <ArrowBackIcon sx={{ fontSize: "25px" }} />
          </Box>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "left",
              height: { xs: "55vh", sm: "65vh", md: "90vh", lg: "90vh" },
            }}
          >
            <Box
              width={{ xs: "80%", sm: "70%", md: "90%", lg: "80%" }}
              mt={{ xs: "60px", md: 0 }}
            >
              <img
                src={BurrstLogo}
                onClick={handleBurrstLogo}
                alt="Burrst Logo"
                width="40%"
                style={{ cursor: "pointer", marginBottom: 15 }}
              />
              <Typography
                color={theme.colors.text.greyLight}
                fontFamily={theme.fonts.russoOne}
                fontWeight={theme.fonts.fontWeightMedium}
                fontSize={{ xs: "24px", sm: "38px", md: "34px", lg: "46px" }}
              >
                THE NEXT GENERATION
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box width={{ xs: "80%", sm: "70%" }} mt={{ xs: "40px", md: 0 }}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: 900,
                  fontSize: { xs: "20px", sm: "28px" },
                  mb: "10px",
                }}
              >
                Login<span style={{ color: "#FF0000" }}>.</span>
              </Typography>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightRegular,
                  fontSize: "18px",
                  marginBottom: "5px",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Email
              </Typography>

              <TextField
                name="email"
                placeholder="Email"
                fullWidth
                value={formData?.email || ""}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={InputEmailIcon} alt="Input Email Icon" />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                    fontSize: "14px",
                    height: "45px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  marginBottom: "20px",
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightRegular,
                  fontSize: "18px",
                  marginBottom: "5px",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Password
              </Typography>

              <TextField
                name="confirmPassword"
                // size="small"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                // autoComplete="off"
                value={formData.password}
                onChange={(e) => handleChange("password", e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleLogin();
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={InputPasswordLockIcon}
                        alt="Input Confirm Password Icon"
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <Visibility
                            sx={{ color: theme.colors.text.greyLight }}
                          />
                        ) : (
                          <VisibilityOff
                            sx={{ color: theme.colors.text.greyLight }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                    fontSize: "14px",
                    height: "45px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
              <Box textAlign="end">
                <Box>
                  <Button
                    onClick={() => navigate("/forgot-password")}
                    sx={{
                      fontWeight: theme.fonts.fontWeightMedium,
                      fontFamily: theme.fonts.livvic,
                      textTransform: "none",
                      color: "white",
                      fontSize: "14px",
                      "@media (max-width: 600px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>
              </Box>
              <Button
                fontFamily={theme.fonts.livvic}
                sx={{
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                  marginBottom: "5px",
                  width: "100%",
                  height: "45px",
                  borderRadius: theme.fonts.smallBorderRadius,
                  backgroundColor: "#DD0000",
                  marginTop: "10px",
                  fontWeight: theme.fonts.fontWeightMedium,
                  fontSize: "18px",
                  color: "white",
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "&:hover": {
                    backgroundColor: "darkred",
                  },
                }}
                disabled={loading}
                onClick={handleLogin}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "gray" }} />
                ) : (
                  "LOGIN"
                )}
              </Button>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: "16px",
                  marginTop: "1%",

                  "@media (max-width: 480px)": {
                    fontSize: "10px",
                  },
                }}
              >
                <span style={{ color: "#c5c5c5", fontWeight: 500 }}>
                  Don’t have an account?
                </span>{" "}
                <Box
                  component="span"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={handleRegistration}
                >
                  Register Now
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={showDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            width: "80vw",
            background: theme.colors.common.black,
            color: theme.colors.common.white,
            borderRadius: theme.fonts.smallBorderRadius,
          },
        }}
        sx={{
          backdropFilter: "blur(3px)",
          backgroundColor: "rgb(255,255,255,0.3)",
        }}
      >
        <GracePeriodExpiredModal handleDialogClose={handleDialogClose} />
      </Dialog>
    </>
  );
};

export default Login;

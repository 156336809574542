import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

import LeftArrow from "../../../../../assets/icons/LeftArrow.svg";

import CampaignsBurrstRecommendedCard from "../../../../../components/afterLogin/athletes/home/subComponents/campaigns/campaigns.list.burrstRecommended.card";
import MoreCampaignsCard from "../../../../../components/afterLogin/athletes/home/subComponents/campaigns/campaigns.list.moreCampaigns.card";
import { getBurrstRecommendedList } from "../../../../../services/campaign/campaign.service";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  SwapVert,
} from "@mui/icons-material";
import CustomSkeleton from "../../../../../themes/@overrides/customSkeleton";
import NoActiveCampaign from "../../../../../assets/icons/NoActiveCampaign.svg";
import { Navigation } from "swiper/modules";

const CampaignsList = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const backState = location?.state?.back;
  const [campaignsList, setCampaignsList] = useState([]);
  const [burrstRecommendedList, setBurrstRecommendedList] = useState([]);
  const [isAllDataLoading, setIsAllDataLoading] = useState(true);

  const [sort, setSort] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const [apiData, setApiData] = useState({
  //   page: 1,
  //   pageSize: 2,
  // });

  const sortByOptionsList = [
    {
      id: 1,
      key: "totalCompensation",
      value: "Price(Low to High)",
      sortOrder: 1,
    },
    {
      id: 2,
      key: "totalCompensation",
      value: "Price(High to Low)",
      sortOrder: -1,
    },
    {
      id: 3,
      key: "startDate",
      value: "Newest First",
      sortOrder: -1,
    },
    {
      id: 4,
      key: "startDate",
      value: "Oldest First",
      sortOrder: 1,
    },
  ];

  const handleSortBy = (e) => {
    setAnchorEl(e.target);
  };

  const handleSortByClose = () => {
    setAnchorEl(null);
  };

  const handleSortByClick = (menuItem) => {
    setSort((preItem) => (preItem?.id === menuItem?.id ? "" : menuItem));
    handleSortByClose();
  };

  const fetchCampaignsList = async () => {
    setIsAllDataLoading(true);
    const response = await getBurrstRecommendedList(sort?.key, sort?.sortOrder);
    setIsAllDataLoading(false);
    setBurrstRecommendedList(response?.data?.data[0]?.burrstRecommended);
    setCampaignsList(response?.data?.data[0]?.moreCampaigns);
  };

  useEffect(() => {
    fetchCampaignsList();
  }, [sort]);

  const handleBack = () => {
    if (backState === "home") {
      navigate("/dashboard/home");
    } else navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Campaigns | Burrst</title>
      </Helmet>
      <Box
        sx={{
          px: { xs: "10px", sm: "30px" },
          mt: { xs: "95px", sm: "115px" },
        }}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          mb="30px"
        >
          <Box display="flex" flexWrap="wrap" alignItems="center" gap={2}>
            <Box
              component="img"
              src={LeftArrow}
              alt="Left Arrow"
              width="30px"
              sx={{ cursor: "pointer" }}
              onClick={handleBack}
            ></Box>
            <Typography
              color={theme.colors.common.white}
              fontFamily={theme.fonts.russoOne}
              fontSize={theme.fonts.heading}
            >
              Campaigns
            </Typography>
          </Box>
        </Box>

        <Box
          my="30px"
          p="20px"
          overflow="hidden"
          sx={{
            background: theme.colors.dashboard.grey200,
          }}
        >
          <Typography
            fontSize={theme.fonts.subHeading}
            fontFamily={theme.fonts.russoOne}
            fontWeight={theme.fonts.fontWeightRegular}
            sx={{
              width: "100%",
            }}
          >
            AI Recommended
          </Typography>

          <Swiper
            grabCursor={true}
            navigation={true}
            modules={[Navigation]}
            slidesPerView={2.5}
            initialSlide={0}
            breakpoints={{
              0: { slidesPerView: 1, spaceBetween: 20 },
              400: { slidesPerView: 1.2, spaceBetween: 20 },
              500: { slidesPerView: 1.5, spaceBetween: 20 },
              600: { slidesPerView: 1.5, spaceBetween: 20 },
              700: { slidesPerView: 1.8, spaceBetween: 20 },
              800: { slidesPerView: 2, spaceBetween: 20 },
              900: { slidesPerView: 1.8, spaceBetween: 20 },
              1000: { slidesPerView: 2, spaceBetween: 20 },
              1200: { slidesPerView: 2.6, spaceBetween: 20 },
              1400: { slidesPerView: 3, spaceBetween: 20 },
              1500: { slidesPerView: 3.2, spaceBetween: 20 },
              1600: { slidesPerView: 3.8, spaceBetween: 20 },
              1800: { slidesPerView: 4, spaceBetween: 20 },
            }}
            style={{ margin: "20px 0", padding: "20px" }}
          >
            {isAllDataLoading ? (
              Array.from({ length: 10 }, (_, index) => (
                <SwiperSlide key={index}>
                  <CampaignsBurrstRecommendedCard
                    isAllDataLoading={isAllDataLoading}
                  />
                </SwiperSlide>
              ))
            ) : burrstRecommendedList?.length ? (
              burrstRecommendedList?.map((detail, index) => (
                <SwiperSlide key={index}>
                  <CampaignsBurrstRecommendedCard detail={detail} />
                </SwiperSlide>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                  fontSize: { xs: "24px", sm: "30px" },
                  minHeight: "250px",
                  width: "100%",
                  background: "rgba(18,18,18,1)",
                  borderRadius: "5px",
                }}
              >
                <Box
                  component="img"
                  src={NoActiveCampaign}
                  alt="No Active Campaign"
                  sx={{ width: { xs: "70px", sm: "100px" } }}
                ></Box>
                No AI recommended campaigns yet!
              </Box>
            )}
          </Swiper>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: "40px",
              mb: "40px",
            }}
          >
            <Typography
              fontSize={theme.fonts.subHeading}
              fontFamily={theme.fonts.russoOne}
              fontWeight={theme.fonts.fontWeightRegular}
            >
              Find More Campaigns
            </Typography>

            {isAllDataLoading ? (
              <CustomSkeleton
                sx={{ width: "100px", height: "40px", borderRadius: "5px" }}
              />
            ) : (
              <Button
                id="sort-button"
                aria-controls={open ? "sort-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                sx={{
                  color: theme.colors.text.greyLightDark,
                  border: `1px solid ${theme.colors.text.greyDark}`,
                  borderRadius: "5px",
                  px: "10px",
                  py: "5px",
                  height: "40px",
                  cursor: "pointer",
                  textTransform: "none",
                }}
                endIcon={<SwapVert />}
                onClick={handleSortBy}
              >
                Sort
              </Button>
            )}

            <Menu
              id="sort-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleSortByClose}
              MenuListProps={{ "aria-labelledby": "sort-button" }}
              anchorOrigin={{
                vertical: 45,
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PopoverClasses={{
                paper: "custom-paper-class",
              }}
              sx={{
                "& .custom-paper-class": {
                  minWidth: "200px",
                  maxWidth: "220px",
                  backgroundColor: "black",
                  color: "white",
                  filter: "drop-shadow(0px 4px 4px rgba(255,255,255,0.3))",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  px: "10px",
                  py: "5px",
                }}
              >
                <Typography sx={{ fontFamily: theme.fonts.livvic }}>
                  Sort By
                </Typography>
                <SwapVert />
              </Box>
              <Divider sx={{ background: theme.colors.text.greyDark }} />
              <Box sx={{ pt: "10px", px: "10px" }}>
                {sortByOptionsList?.map((menuItem, index) => (
                  <MenuItem
                    onClick={() => handleSortByClick(menuItem)}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      background: "rgba(39,39,39,1)",
                      color: theme.colors.text.greyLight,
                      borderRadius: "5px",
                      mt: index === 0 ? 0 : 0.8,
                      px: "8px",
                      py: "3px",
                      fontSize: "14px",
                      fontFamily: theme.fonts.livvic,
                      "&:hover": {
                        background: "rgba(18,18,18,1)",
                        color: "white",
                      },
                    }}
                  >
                    <Typography>{menuItem?.value}</Typography>
                    {sort?.id === menuItem?.id ? (
                      <RadioButtonChecked sx={{ color: "red" }} />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                  </MenuItem>
                ))}
              </Box>
            </Menu>
          </Box>
          <Grid container spacing={3}>
            {isAllDataLoading ? (
              Array.from({ length: 10 }, (_, index) => (
                <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <MoreCampaignsCard isAllDataLoading={isAllDataLoading} />
                </Grid>
              ))
            ) : campaignsList?.length ? (
              campaignsList?.map((detail, index) => (
                <Grid item key={index} xs={12} sm={6} md={6} lg={4} xl={3}>
                  <MoreCampaignsCard detail={detail} key={index} />
                </Grid>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 2,
                  fontSize: { xs: "24px", sm: "30px" },
                  minHeight: "250px",
                  width: "100%",
                  background: "rgba(18,18,18,1)",
                  borderRadius: "5px",
                }}
              >
                <Box
                  component="img"
                  src={NoActiveCampaign}
                  alt="No Active Campaign"
                  sx={{ width: { xs: "70px", sm: "100px" } }}
                ></Box>
                No campaigns yet!
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default CampaignsList;

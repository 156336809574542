import axios from "axios";
import { getToken } from "../../routes";
import {
  BASE_API_URL,
  REDIRECT_TO_VIEW_APPLICANTS,
} from "../../utils/constants";

const base = BASE_API_URL;
//const base = `https://75af-2405-201-a00b-879-bc6e-33e2-25c1-6fe3.ngrok-free.app/api/v1`;

const handleError = (error) => {
  if (error?.response?.data?.status === 500) return "Internal Server Error";
  else return error?.response?.data?.message || "Some Error Occurred";
};

export const campaignFeatured = async (path) => {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
    });

    const results = await response.json();

    if (results.status !== 200) {
      return { success: false, message: results.message };
    }
    return results;
  } catch (error) {
    return { success: false, message: error };
  }
};

export async function createCampaignApi(path, formData) {
  try {
    const url = `${base}${path}`;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        case "sports":
          form.append("sports", formData.sports.join(","));
          break;
        case "additionalSkills":
          form.append(
            "additionalSkills",
            JSON.stringify(formData.additionalSkills)
          );
          break;
        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function updateCampaignApi(path, formData) {
  const token = localStorage.getItem("token");
  try {
    const url = `${base}${path}`;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        case "sports":
          form.append("sports", formData.sports.join(","));
          break;
        case "additionalSkills":
          form.append(
            "additionalSkills",
            JSON.stringify(formData.additionalSkills)
          );
          break;
        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function createDeliverableApi(path, formData) {
  try {
    const url = `${base}${path}`;
    delete formData.additionalSkills;
    delete formData.token;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        case "activities":
          form.append("activities", formData?.activities.join(","));
          break;
        case "additionalSkills":
          form.append(
            "additionalSkills",
            JSON.stringify(formData.additionalSkills)
          );
          break;
        case "additionalProjectFiles":
          for (const file of formData.additionalProjectFiles) {
            form.append("additionalProjectFiles", file);
          }
          break;

        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function updateCampaignDeliverableApi(path, formData) {
  const token = localStorage.getItem("token");
  try {
    const url = `${base}${path}`;
    //const token = formData.token;
    delete formData.additionalSkills;
    delete formData.token;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        case "activities":
          form.append("activities", formData?.activities.join(","));
          break;
        case "additionalProjectFiles":
          for (const file of formData?.additionalProjectFiles) {
            form.append("additionalProjectFiles", file);
          }
          break;

        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "PUT",
      mode: "cors",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}
export const deleteDeliverableApi = async (path, body) => {
  const token = localStorage.getItem("token");
  try {
    const url = `${base}${path}`;
    const response = await axios.delete(url, {
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return { status: "error", message: error };
  }
};

export async function createContractApi(path, formData) {
  try {
    const url = `${base}${path}`;
    //const token = formData.token;
    delete formData.token;
    const form = new FormData();
    for (const key of Object.keys(formData)) {
      switch (key) {
        default:
          form.append(key, formData[key]);
          break;
      }
    }
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: form,
    });
    const results = await response.json();
    if (results.status !== 200) {
      return { status: "error", message: results.message };
    }
    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function myCampaignsListApi(path, body) {
  try {
    const url = `${base}${path}?stage=4&page=${
      body?.page ? `${body?.page}` : ""
    }&pageSize=${body?.pageSize ? `${body?.pageSize}` : ""}`; //?stage=4
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function campaignDetailsApi(path, campaignId) {
  try {
    const url = `${base}${path}?campaignId=${
      campaignId || localStorage.getItem("campaignId")
    }`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getappliedAthletesApi(path, body) {
  try {
    const url = `${base}${path}?campaignId=${localStorage.getItem(
      "campaignId"
    )}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export async function selectedAthleteApi(path) {
  try {
    const url = `${base}${path}?campaignId=${localStorage.getItem(
      "campaignId"
    )}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function respondToApplicantApi(
  path,
  selectedId,
  ignoredId,
  acceptedListToUrl = {}
) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        campaignId: localStorage.getItem("campaignId"),
        acceptedList: selectedId,
        rejectedList: ignoredId,
        redirectUri: REDIRECT_TO_VIEW_APPLICANTS,
        acceptedListToUrl,
      },
    });

    return response;
  } catch (error) {
    if (
      (error?.response?.data?.status === 400 &&
        error?.response?.data?.data?.reason === "NOT_IN_APPLICATIONS") ||
      "MULTIPLE_ATHELTES_NOT_ALLOWED"
    ) {
      return error?.response?.data;
    } else {
      return handleError(error);
    }
  }
}

export async function removeApplicationApi(path, body) {
  try {
    const campaignId = localStorage.getItem("campaignId");
    const url = `${base}${path}?campaignId=${campaignId}&athleteId=${body}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export const getCampaignsApi = async (path, sortBy, sortOrder = -1) => {
  const token = getToken();
  try {
    const url = `${base}${path}${
      sortBy ? `?sortBy=${sortBy}&sortOrder=${sortOrder}` : ""
    }`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    if (
      error?.response?.data?.status === 400 &&
      error?.response?.data?.data?.reason === "ALREADY_SELECTED"
    ) {
      return error?.response;
    } else {
      if (
        error?.response?.data?.status === 400 &&
        error?.response?.data?.data?.plaidAccount === false
      ) {
        return error?.response;
      } else return handleError(error);
    }
  }
};

export const postCampaignsApi = async (path, body) => {
  const token = getToken();
  let form;

  if (body instanceof FormData) {
    form = body;
  } else {
    form = new FormData();
    for (const key in body) {
      form.append(key, body[key]);
    }
  }

  try {
    const url = `${base}${path}`;
    const response = await axios(url, {
      method: "POST",
      mode: "cors",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      data: form,
    });
    // const results = await response.json();
    // if (results.status !== 200) {
    //   return { status: "error", message: results.message };
    // }
    return response;
  } catch (error) {
    return handleError(error);
  }
};

export async function removeProjectFilesApi(path, id, data) {
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: localStorage.getItem("campaignId"),
        deliverableId: id,
        projectFile: data,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return results;
  } catch (error) {
    return { status: "error", message: error };
  }
}

export const respondToProofApi = async (path, data) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
      params: data,
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export const getAiRecommendedAthletesApi = async (path, body) => {
  const token = getToken();
  const campaignId = localStorage.getItem("campaignId");
  try {
    const url = `${base}${path}?campaignId=${campaignId}&page=${
      body?.page ? `${body?.page}` : ""
    }&pageSize=${body?.pageSize ? `${body?.pageSize}` : ""}`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    return handleError(error);
  }
};

export async function showInterestAiAthleteApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: localStorage.getItem("campaignId"),
        athleteId: body.athleteId,
        type: body.type,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return results;
  } catch (error) {
    return handleError(error);
  }
}

export const dealTrackerApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    return results;
  } catch (error) {
    return handleError(error);
  }
};

export async function campaignAddRevenueApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: localStorage.getItem("campaignId"),
        deliverableId: body.deliverableId,
        date: body.date,
        amount: body.amount,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function campaignEditRevenueApi(path, body) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: localStorage.getItem("campaignId"),
        deliverableId: body.deliverableId,
        revenueId: body.revenueId,
        amount: body.amount,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function getCampaignPaymentModelListApi(path) {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });

    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export async function campaignRevenueMakePaymentApi(path, body) {
  const token = getToken();
  const campaignId = localStorage.getItem("campaignId");
  try {
    const url = `${base}${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        campaignId: campaignId,
        deliverableId: body?.deliverableId,
        revenueId: body?.revenueId,
      }),
    });
    const results = await response.json();

    if (response.status !== 200) {
      return { status: "error", message: results.message };
    }

    return results;
  } catch (error) {
    return { status: "error", message: error.message };
  }
}

export const getTrackingApi = async (path) => {
  const token = getToken();
  try {
    const url = `${base}${path}`;
    const response = await axios(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return handleError(error);
  }
};

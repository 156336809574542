import React, { useState, useRef } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import CrossIcon from "../../../../../../assets/icons/CrossIcon.svg";
import { toast } from "react-toastify";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteIcon from "../../../../../../assets/icons/Delete.svg";
import {
  createDeliverable,
  updateCampaignDeliverable,
} from "../../../../../../services/campaign/campaign.service";

const AddDeliverableModal = ({
  paymentModel,
  handleDeliverableModalClose,
  isAdded,
  setIsAdded,
  editDeliverable,
  campaignId,
}) => {
  const theme = useTheme();

  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    campaignId: campaignId,
    ...(editDeliverable?._id ? { deliverableId: editDeliverable?._id } : {}),
    ...(editDeliverable?._id ? { removeFiles: [] } : {}),
    append: true,
    activities: editDeliverable?.activities || [],
    compensation: editDeliverable?.compensation || "",
    additionalProjectFiles: editDeliverable?.additionalProjectFiles || [],
    proofOfWork: true,
    finalize: true,
  });

  const handleTextFieldClick = () => {
    fileInputRef.current.click();
  };

  const isValidUrl = (url) => {
    const urlPattern =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}(:[0-9]{1,5})?(\/\S*)?$/;

    return urlPattern.test(url);
  };

  const handleAdditionalFilesDelete = (index) => {
    const updatedFiles = formData?.additionalProjectFiles;
    const removedFiles = updatedFiles?.splice(index, 1);

    setFormData((prevData) => ({
      ...prevData,
      additionalProjectFiles: updatedFiles,
      ...(editDeliverable
        ? { removeFiles: [...prevData?.removeFiles, removedFiles] }
        : {}),
    }));
  };

  const handleSelectionsChange = (field, value) => {
    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: value,
    }));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target?.files[0];
    if (formData?.additionalProjectFiles?.length < 5) {
      const isFileSizeValid = selectedFile?.size <= 5 * 1024 * 1024;

      if (!isFileSizeValid) {
        toast.error("File size should be less than 5MB");
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        additionalProjectFiles: [
          ...prevData?.additionalProjectFiles,
          selectedFile,
        ],
      }));
    } else {
      toast.error("Maximum limit of 5 files reached");
    }
  };

  const handleAdd = async () => {
    if (!formData?.activities || !formData?.compensation) {
      toast.error("Please fill in all the required fields");
      return;
    }
    const isCompensationValid =
      !isNaN(formData?.compensation) && formData?.compensation !== "";

    if (!isCompensationValid) {
      toast.error("Compensation must be a valid number");
      return;
    }

    if (
      paymentModel === "Revenue Share" &&
      parseFloat(formData?.compensation) >= 100
    ) {
      alert("Value must be less than 100");
      return;
    }

    // const isUrlValid = isValidUrl(formData?.deliverableUrl);

    // if (!isUrlValid) {
    //   toast.error("Please enter a valid URL for Deliverable URL");
    //   return;
    // }

    setLoading(true);
    if (!editDeliverable) {
      delete formData?.append;
      const response = await createDeliverable(formData);
      if (response?.status === 200) {
        toast.success("Deliverable Creation Successful.");
      } else toast.error("Deliverable Creation Failed");
    } else {
      delete formData?.finalize;
      const response = await updateCampaignDeliverable(formData);
      if (response?.status === 200) {
        toast.success("Deliverable Updated Successfully.");
      } else toast.error("Deliverable Updation Failed");
    }
    setIsAdded(!isAdded);
    handleDeliverableModalClose();
    setLoading(false);
  };

  return (
    <DialogContent
      sx={{
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
          width: "2px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "grey",
          borderRadius: "4px",
        },
      }}
    >
      <Box
        component="img"
        src={CrossIcon}
        alt="Cross Icon"
        position="absolute"
        right="20px"
        top="20px"
        sx={{ cursor: "pointer", width: "16px" }}
        onClick={handleDeliverableModalClose}
      ></Box>
      <Box>
        <Grid
          container
          spacing={3}
          sx={{
            display: "flex",
            flexDirection: "column",
            py: "40px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "18px",
                marginBottom: "1%",
                "@media (max-width: 480px)": {
                  fontSize: "15px",
                },
              }}
            >
              Activities
            </Typography>

            <Autocomplete
              fullWidth
              required
              name="activities"
              multiple
              value={formData?.activities || []}
              freeSolo
              onChange={(e, newValue) =>
                handleSelectionsChange("activities", newValue)
              }
              options={[
                "Instagram post",
                "Instagram story",
                "Instagram reels",
                "Instagram live",
                "Tiktok post",
                "Facebook Post",
                "Facebook live",
                "Facebook story",
                "Youtube post",
                "LinkedIn post",
                "Twitter post",
              ]}
              renderInput={(params) => (
                <TextField {...params} required placeholder="Select" />
              )}
              renderTags={(value, getTagProps) =>
                value?.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...getTagProps({ index })}
                    sx={{
                      color: theme.colors.common.white,
                      backgroundColor:
                        theme.colors.background.autocompleteRenderTagsLightgray,
                    }}
                  />
                ))
              }
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    background: theme.colors.background.formInput,
                    color: theme.colors.text.greyLight,
                    borderRadius: "0 0 10px 10px",
                    "& .MuiAutocomplete-noOptions": {
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  {...props}
                />
              )}
              isOptionEqualToValue={(option, value) => value?.includes(option)}
              sx={{
                border: `1px solid ${theme.colors.common.white}`,
                background: theme.colors.background.formInput,
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
                ".MuiInputBase-root,button": {
                  color: theme.colors.text.greyLight,
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      borderColor: theme.colors.common.white,
                      borderRadius: theme.fonts.smallBorderRadius,
                    },
                  },
                },
                "& input, & textarea, & select": {
                  WebkitTextFillColor: "#FFFFFF",
                  WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                  transition: "background-color 5000s ease-in-out 0s",
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            {paymentModel === "Revenue Share" ? (
              <>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "18px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Compensation
                </Typography>

                <TextField
                  name="compensation"
                  placeholder="Percentage"
                  fullWidth
                  // autoComplete="off"
                  value={formData?.compensation || ""}
                  onChange={(e) =>
                    handleSelectionsChange("compensation", e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          color: theme.colors.common.white,
                        }}
                      >
                        <Typography
                          sx={{
                            borderLeft: "1px solid #797979",
                            pl: "5px",
                            color: "#797979",
                            fontSize: "20px",
                          }}
                        >
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.text.greyLight}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: theme.fonts.smallBorderRadius,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                />
              </>
            ) : (
              <>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "18px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Compensation
                </Typography>

                <TextField
                  name="compensation"
                  placeholder="Amount"
                  fullWidth
                  // autoComplete="off"
                  value={formData?.compensation || ""}
                  onChange={(e) =>
                    handleSelectionsChange("compensation", e.target.value)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          color: theme.colors.common.white,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: theme.fonts.fontWeightMedium,
                          }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    sx: {
                      border: `1px solid ${theme.colors.text.greyLight}`,
                      background: theme.colors.background.formInput,
                      color: theme.colors.common.white,
                      borderRadius: theme.fonts.smallBorderRadius,
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      color: theme.colors.text.greyLight,
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused": {
                        fieldset: {
                          borderColor: theme.colors.common.white,
                        },
                      },
                    },
                    "& input, & textarea, & select": {
                      WebkitTextFillColor: "#FFFFFF",
                      WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                      transition: "background-color 5000s ease-in-out 0s",
                    },
                  }}
                />
              </>
            )}
          </Grid>

          {/* <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "18px",
                marginBottom: "1%",
                "@media (max-width: 480px)": {
                  fontSize: "15px",
                },
              }}
            >
              Product/Service URL
            </Typography>

            <TextField
              name="deliverableurl"
              placeholder="URL"
              fullWidth
              // autoComplete="off"
              value={formData?.deliverableUrl}
              onChange={(e) =>
                handleSelectionsChange("deliverableUrl", e.target.value)
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      color: theme.colors.common.white,
                    }}
                  >
                    <Box
                      component="img"
                      src={UrlIcon}
                      alt="URL Icon"
                      sx={{ width: "16px" }}
                    ></Box>
                  </InputAdornment>
                ),
                sx: {
                  border: `1px solid ${theme.colors.text.greyLight}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                },
              }}
              InputLabelProps={{
                sx: {
                  color: theme.colors.text.greyLight,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      borderColor: theme.colors.common.white,
                    },
                  },
                },
                "& input, & textarea, & select": {
                  WebkitTextFillColor: "#FFFFFF",
                  WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                  transition: "background-color 5000s ease-in-out 0s",
                },
              }}
            />
          </Grid> */}

          <Grid item xs={12}>
            <Typography
              fontFamily={theme.fonts.livvic}
              sx={{
                fontWeight: theme.fonts.fontWeightBold,
                fontSize: "18px",
                marginBottom: "1%",
                "@media (max-width: 480px)": {
                  fontSize: "15px",
                },
              }}
            >
              Social Proof{" "}
              <span
                style={{
                  fontFamily: theme.fonts.livvic,
                  fontSize: "16px",
                  fontWeight: theme.fonts.fontWeightRegular,
                  color: theme.colors.text.greyDark,
                }}
              >
                (you may attach up to 5 files under 5MB each) (Optional)
              </span>
            </Typography>

            <Box component="label">
              <TextField
                name="additionalProject"
                placeholder="Please select your file here"
                fullWidth
                InputProps={{
                  readOnly: true,
                  sx: {
                    border: `1px solid ${theme.colors.text.greyLight}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                onClick={handleTextFieldClick}
              />
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
            {formData?.additionalProjectFiles?.map((file, index) => (
              <Box
                key={index}
                display="flex"
                backgroundColor="#0E0E0E"
                border="1px solid #797979"
                alignItems="center"
                borderRadius="5px"
                my="8px"
                p="5px"
              >
                <Box
                  backgroundColor="#5e5d5d"
                  borderRadius="5px"
                  p="1px"
                  mr="10px"
                >
                  <InsertDriveFileOutlinedIcon
                    sx={{ marginTop: "3px", cursor: "pointer" }}
                  />
                </Box>
                <Typography>
                  {/* {detail.file ? detail.file.split("/").pop() : ""} */}
                  {file?.name
                    ? file?.name
                    : file?.length > 40
                    ? `${file?.substring(0, 40)}...`
                    : file}
                </Typography>
                <Box ml="auto" pr="10px">
                  <img
                    onClick={() => handleAdditionalFilesDelete(index)}
                    src={DeleteIcon}
                    alt="Delete icon"
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            ))}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              sx={{
                borderRadius: "5px",
                textTransform: "none",
                p: "5px 10px",
                mt: "20px",
                height: "42px",
                width: "170px",
                color: theme.colors.common.white,
                background: theme.colors.text.red,
                cursor: "pointer",
                fontWeight: theme.fonts.fontWeightBold,
                "&:hover": {
                  background: theme.colors.divider.red,
                },
                "&.MuiButton-root.Mui-disabled": {
                  color: "white",
                  background: "rgba(255,0,0,0.6)",
                },
              }}
              disabled={loading}
              onClick={handleAdd}
            >
              {loading ? <CircularProgress sx={{ color: "white" }} /> : "ADD"}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );
};

export default AddDeliverableModal;

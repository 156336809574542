import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { Helmet } from "react-helmet-async";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import BurrstLogo from "../../../assets/logo/BurrstLogo.svg";
import MyProfileAthleteBackground from "../../../assets/images/BackgroundImage2.png";
import PlusIconPortfolio from "../../../assets/icons/PlusIconPortfolio.svg";
import BrowseFileIcon from "../../../assets/icons/BrowseFileIcon.svg";
import WebsiteUrlIcon from "../../../assets/icons/WebsiteUrlIcon.svg";
import CloseIcon from "../../../assets/icons/CrossIcon.svg";
import RegistrationBackground from "../../../assets/images/RegistrationBackground.jpg";

import { PADDING_X, BASE_IMAGE_URL } from "../../../utils/constants";
import { toast } from "react-toastify";
import {
  dataCityList,
  dataCountryList,
  dataStateList,
} from "../../../services/data/data.service";
import {
  brandBusinessCategories,
  brandProfileUpdate,
} from "../../../services/brand/brand.service";
import Schema from "../../../joi.schema/beforeLogin/brand/brand.PublicProfile.Schema";
import BrandRegSuccessfulModal from "../../../components/beforeLogin/brands/subComponents/brandRegSuccessfulModal";
import { getToken } from "../../../routes";

const PublicProfileBrand = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const inputRef = useRef(null);
  const BurrstLogo = `${BASE_IMAGE_URL}/burrst_png.png`;
  // const MyProfileAthleteBackground = `${BASE_IMAGE_URL}/MyProfileAthleteBackground.svg`;
  //const MyProfileAthleteBackground = `${BASE_IMAGE_URL}/MyProfileAthleteBackground.png`;

  const location = useLocation();
  const token = location?.state?.registrationToken || getToken();

  const detail = location?.state?.detail;
  const isMyProfile = location?.state?.isMyProfile;
  const tokenName = isMyProfile ? "loginToken" : "registrationToken";

  // const getFileFromUrl = async (url) => {
  //   try {
  //     const response = await axios.get(url, { responseType: "blob" });
  //     const blob = new Blob([response?.data]);
  //     const file = new File([blob], "filename");
  //     return file;
  //   } catch (error) {
  //     console.error("Error converting URL to file:", error);
  //   }
  //   return null;
  // };
  const [loading, setLoading] = useState(false);
  const [successRegModalOpen, setSuccessRegModalOpen] = useState(false);
  const [businessOptions, setBusinessOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isCountryChange, setIsCountryChange] = useState(false);
  const [isStateChange, setIsStateChange] = useState(false);

  const [formData, setFormData] = useState({
    brandId: detail?._id || location.state.brandId,
    [tokenName]: token,
    images: detail?.images || [],
    videoHighlights: detail?.videoHighlights || [],
    businessCategory: detail?.businessCategory?.split(",") || [],
    companyName: detail?.companyName || "",
    // einNumber: detail?.einNumber || "",
    country: detail?.country || "",
    state: detail?.state || "",
    city: detail?.city || "",
    about: detail?.about || "",
    websiteUrl: detail?.websiteUrl || "",
    brandLogo: detail?.brandLogo || null,
    appendImages: false,
    appendVideos: false,
  });

  const handleSelectionsChange = (field, value) => {
    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: value,
    }));
  };

  const handleAutocompleteChange = (field, newValue) => {
    const userInput = newValue?.inputValue || newValue || "";

    setFormData((prevSelections) => ({
      ...prevSelections,
      [field]: userInput,
    }));
  };

  // const handleEinSelectionsChange = (name, value) => {
  //   setFormData({
  //     ...formData,
  //     [name]: cc_format(value),
  //   });
  // };

  //const [aboutUs, setAboutUs] = useState("");
  const maxLength = 5000;

  const handlesuccessModalClose = () => {
    setSuccessRegModalOpen(false);
  };
  const handleBurrstLogo = () => {
    navigate("/");
  };
  const handleImageChange = (e) => {
    const files = e.target.files;
    const newImages = Array.from(files).map(
      (file) => file
      // url: URL.createObjectURL(file),
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      images: prevFormData.images.concat(newImages),
    }));
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      brandLogo: file,
    });
  };

  const handleInputClick = () => {
    inputRef.current.click();
  };

  const handleEinSelectionsChange = (name, value) => {
    // Format the input to add a hyphen after the 2nd character and 7th character
    //const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 9);
    let formattedValue;
    if (value.length > 2) {
      const firstString = value.slice(0, 2);
      const secondString = value.slice(3, 10);
      formattedValue = `${firstString}-${secondString}`;
    } else {
      formattedValue = value;
    }
    // .replace(/^({2})(\d{7})$/, "$1-$2");
    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };

  const handleDeleteImage = (index) => {
    setFormData((prevFormData) => {
      const newImages = [...prevFormData.images];
      newImages.splice(index, 1);
      return {
        ...prevFormData,
        images: newImages,
      };
    });
  };

  const handleSubmit = async () => {
    const { error } = Schema.validate(formData);
    if (!formData?.brandLogo) {
      toast.error("Brand logo is required and must be a valid file.");
    } else if (error) {
      error?.details?.forEach((errors) => {
        toast.error(errors.message);
      });
    } else {
      try {
        if (typeof formData?.brandLogo === "string") {
          delete formData?.brandLogo;
        }
        setLoading(true);
        const response = await brandProfileUpdate({
          ...formData,
          businessCategory: formData?.businessCategory?.join(","),
        });
        setLoading(false);
        if (response?.status === 200) {
          isMyProfile
            ? navigate("/dashboard/home/my-profile", {
                replace: true,
                state: { isMyProfile: true },
              })
            : localStorage.clear();
          //navigate("/login", { replace: true });
          setSuccessRegModalOpen(true);
        } else {
          toast.error(response?.message || response?.status);
        }
      } catch (error) {
        toast.error(error.message || error.status);
      }
    }
  };

  const fetchBusinessCatagories = async () => {
    const response = await brandBusinessCategories();

    if (response?.status === 200) {
      setBusinessOptions(response?.data);
    } else {
      console.error("Failed to fetch business options:", response?.message);
    }
  };

  const fetchCountryOptions = async () => {
    const response = await dataCountryList("");

    if (response?.status === 200) {
      setCountryOptions(response?.data);
    } else {
      console.error("Failed to fetch country options:", response?.message);
    }
  };

  const fetchStateOptions = async () => {
    const response = await dataStateList(formData?.country);

    if (response?.status === 200) {
      setStateOptions(response?.data);
    } else {
      console.error("Failed to fetch country options:", response?.message);
    }
  };

  const fetchCityOptions = async () => {
    const response = await dataCityList(formData?.country, formData?.state);

    if (response?.status === 200) {
      setCityOptions(response?.data);
    } else {
      console.error("Failed to fetch country options:", response?.message);
    }
  };

  useEffect(() => {
    fetchBusinessCatagories();
    fetchCountryOptions();

    // for editing this page
    if (isMyProfile) {
      fetchStateOptions();
      fetchCityOptions();
    }
  }, []);

  useEffect(() => {
    if (isCountryChange) {
      setFormData((preValue) => ({ ...preValue, state: "", city: "" }));
      setCityOptions([]);
      if (formData?.country) {
        fetchStateOptions();
      }
      setIsCountryChange(false);
    }
  }, [formData?.country]);

  useEffect(() => {
    if (isStateChange) {
      setFormData((preValue) => ({ ...preValue, city: "" }));
      if (formData?.country && formData?.state) {
        if (stateOptions.length) fetchCityOptions();
      }
      setIsStateChange(false);
    }
  }, [formData?.state]);

  return (
    <>
      <Helmet>
        <title>Public Profile Brand | Burrst</title>
      </Helmet>
      <Box>
        <Box
          component="img"
          src={RegistrationBackground}
          alt="MyProfileAthlete Background"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            objectFit: "cover",
            zIndex: "-1",
            height: "100vh",
            width: "80vw",
          }}
        ></Box>
        <Grid
          container
          spacing={2}
          sx={{
            background: `url(${MyProfileAthleteBackground}) no-repeat`,
            backgroundSize: "cover",
            py: "40px",
            paddingX: PADDING_X,
            "@media (max-width:600px)": {
              paddingX: "10px",
            },
          }}
        >
          {/* <ToastContainer position="top-right" autoClose={5000} /> */}

          <Box
            sx={{
              marginBottom: "2%",
              width: "100%",
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Box
              component="img"
              src={BurrstLogo}
              onClick={handleBurrstLogo}
              alt="Burrst Logo"
              width="200px"
              style={{
                cursor: "pointer",
              }}
            ></Box>
          </Box>
          <Grid container spacing={3} padding="15px">
            <Grid item xs={12}>
              <Typography
                fontFamily={theme.fonts.livvic}
                fontSize="38px"
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  marginBottom: "2%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Profile
                <span style={{ color: theme.colors.background.registerButton }}>
                  .
                </span>
              </Typography>

              {!isMyProfile && (
                <Typography
                  fontFamily={theme.fonts.livvic}
                  fontSize="20px"
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Product or Service Highlights
                  <span
                    style={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "16px",
                      fontWeight: theme.fonts.fontWeightRegular,
                      color: theme.colors.text.greyDark,
                    }}
                  >
                    {" "}
                    (Optional)
                  </span>
                </Typography>
              )}

              {/* ----------------Images--------------------- */}
              {!isMyProfile && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    flexWrap: "wrap",
                    gap: 2,
                  }}
                >
                  {formData?.images?.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "130px",
                        width: "150px",
                        borderRadius: theme.fonts.smallBorderRadius,
                      }}
                    >
                      <Box
                        component="img"
                        onLoad={() => URL.revokeObjectURL(image)}
                        src={isMyProfile ? image : URL.createObjectURL(image)}
                        //src={isMyProfile ? image : image.url}
                        alt="Portfolio Image"
                        sx={{
                          width: "100%",
                          height: "100%",
                          borderRadius: theme.fonts.smallBorderRadius,
                        }}
                      ></Box>
                      <Box
                        component="img"
                        src={CloseIcon}
                        alt="Close Icon"
                        sx={{
                          position: "absolute",
                          marginBottom: "112px",
                          marginLeft: "125px",
                          //left: "10%",
                          //right: "0%",
                          width: "10px",
                          cursor: "pointer",
                          display: "flex",
                          //justifyItems: "end",
                          //alignItems: "flex-start",
                          color: theme.colors.common.white,
                        }}
                        onClick={() => handleDeleteImage(index)}
                      ></Box>
                    </Box>
                  ))}

                  <Box
                    component="label"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "130px",
                      width: "150px",
                      cursor: "pointer",
                      borderRadius: theme.fonts.smallBorderRadius,
                      border: `1px solid ${theme.colors.text.greyDarker}`,
                    }}
                  >
                    <Box
                      component="img"
                      src={PlusIconPortfolio}
                      alt="Plus Icon Portfolio"
                    ></Box>
                    <Input
                      id="imageInput"
                      type="file"
                      inputProps={{ accept: "image/*" }}
                      onChange={handleImageChange}
                      sx={{ display: "none" }}
                    />
                  </Box>
                </Box>
              )}
            </Grid>

            {/* ------------------------Grid Contents----------------------- */}

            {!isMyProfile && (
              <Grid item xs={12} lg={6}>
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  Logo{" "}
                  <span
                    style={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "16px",
                      fontWeight: theme.fonts.fontWeightRegular,
                      color: theme.colors.text.greyDark,
                    }}
                  >
                    (Maximum logo ratio is 250 * 100PX and size is 20MB)
                  </span>
                </Typography>
                <Box
                  onClick={handleInputClick}
                  //component="label"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    background: theme.colors.background.formInput,
                    borderRadius: theme.fonts.smallBorderRadius,
                    cursor: "pointer",
                    border: `1px solid ${theme.colors.common.white}`,
                  }}
                >
                  <Box
                    //component="label"
                    sx={{
                      width: "50px",
                      height: "50px",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      component="img"
                      src={BrowseFileIcon}
                      alt="Browse File Icon"
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    ></Box>
                    {/* <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    onChange={handleLogoChange}
                    sx={{ display: "none" }}
                  /> */}
                  </Box>
                  {formData?.brandLogo && formData?.brandLogo?.size > 0 ? (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{ paddingY: "12px", paddingX: "5px" }}
                    >
                      {formData?.brandLogo?.name}
                    </Typography>
                  ) : (
                    <Typography
                      fontFamily={theme.fonts.livvic}
                      sx={{
                        paddingY: "12px",
                        paddingX: "5px",
                        color: theme.colors.text.greyLight,
                      }}
                    >
                      {isMyProfile ? formData?.brandLogo : "Brand Logo"}
                    </Typography>
                  )}
                  <input
                    accept="image/*"
                    type="file"
                    ref={inputRef}
                    //inputProps={{ accept: "image/*" }}
                    onChange={handleLogoChange}
                    style={{ display: "none" }}
                  />
                </Box>
              </Grid>
            )}

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Website URL
              </Typography>
              <TextField
                name="websiteUrl"
                placeholder="Website URL"
                fullWidth
                // autoComplete="off"
                value={formData?.websiteUrl}
                onChange={(e) =>
                  handleSelectionsChange("websiteUrl", e.target.value)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={WebsiteUrlIcon} alt="Website Url Icon" />
                    </InputAdornment>
                  ),
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Company Name
              </Typography>

              {/* <Autocomplete
              fullWidth
              required
              name="companyName"
              options={[]}
              renderInput={(params) => (
                <TextField {...params} required placeholder="Company Name" />
              )}
              autoComplete="off"
              sx={{
                border: `1px solid ${theme.colors.common.white}`,
                background: theme.colors.background.formInput,
                color: theme.colors.common.white,
                borderRadius: theme.fonts.smallBorderRadius,
                ".MuiInputBase-root,button": {
                  color: theme.colors.text.greyLight,
                },

                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused": {
                    fieldset: {
                      borderColor: theme.colors.common.white,
                      borderRadius: theme.fonts.smallBorderRadius,
                    },
                  },
                },
              }}
            /> */}
              <TextField
                name="companyName"
                placeholder="Company Name"
                fullWidth
                // autoComplete="off"
                value={formData?.companyName}
                onChange={(e) =>
                  handleSelectionsChange("companyName", e.target.value)
                }
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            {/* <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                EIN Number{" "}
              </Typography>
              <TextField
                name="einNumber"
                placeholder="xx-xxxxxxx"
                fullWidth
                autoComplete="off"
                value={formData?.einNumber}
                onChange={(e) =>
                  handleEinSelectionsChange("einNumber", e.target.value)
                }
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                  // inputComponent: (props) => (
                  //   <InputMask
                  //     {...props}
                  //     mask="99-9999999"
                  //     maskChar=""
                  //     value={props.value}
                  //     onChange={props.onChange}
                  //   />
                  // ),
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                }}
              />
            </Grid> */}

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Business Category
              </Typography>
              <Autocomplete
                fullWidth
                required
                name="businessCategory"
                options={businessOptions}
                value={formData?.businessCategory || []}
                freeSolo
                multiple
                onChange={(event, newValue) =>
                  handleAutocompleteChange("businessCategory", newValue)
                }
                // filterOptions={(options, { inputValue }) => {
                //   return [...options, inputValue];
                // }}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                renderTags={(value, getTagProps) =>
                  value?.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                      sx={{
                        color: theme.colors.common.white,
                        backgroundColor:
                          theme.colors.background
                            .autocompleteRenderTagsLightgray,
                      }}
                    />
                  ))
                }
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
              {/* <TextField
                name="businessCategory"
                placeholder="Business Category"
                fullWidth
                autoComplete="off"
                value={formData?.businessCategory}
                onChange={(e) =>
                  handleSelectionsChange("businessCategory", e.target.value)
                }
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                }}
              /> */}
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                Country
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="country"
                options={countryOptions}
                value={formData?.country}
                onChange={(event, newValue) => {
                  setIsCountryChange(true);
                  handleSelectionsChange("country", newValue);
                }}
                // error={!!formErrors.country}
                // helperText={formErrors.country}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                State
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="state"
                options={stateOptions.length ? stateOptions : ["N/A"]}
                value={formData?.state}
                onChange={(event, newValue) => {
                  setIsStateChange(true);
                  handleSelectionsChange("state", newValue);
                }}
                // error={!!formErrors.state}
                // helperText={formErrors.state}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={isMyProfile ? 12 : 6}>
              <Typography
                fontFamily={theme.fonts.livvic}
                sx={{
                  fontWeight: theme.fonts.fontWeightBold,
                  fontSize: "20px",
                  marginBottom: "1%",
                  "@media (max-width: 480px)": {
                    fontSize: "15px",
                  },
                }}
              >
                City
              </Typography>

              <Autocomplete
                fullWidth
                required
                name="city"
                options={cityOptions.length ? cityOptions : ["N/A"]}
                value={formData?.city}
                onChange={(event, newValue) =>
                  handleSelectionsChange("city", newValue)
                }
                // error={!!formErrors.city}
                // helperText={formErrors.city}
                renderInput={(params) => (
                  <TextField {...params} required placeholder="Select" />
                )}
                // autoComplete="off"
                sx={{
                  border: `1px solid ${theme.colors.common.white}`,
                  background: theme.colors.background.formInput,
                  color: theme.colors.common.white,
                  borderRadius: theme.fonts.smallBorderRadius,
                  ".MuiInputBase-root,button": {
                    color: theme.colors.text.greyLight,
                  },

                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                        borderRadius: theme.fonts.smallBorderRadius,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      background: theme.colors.background.formInput,
                      color: theme.colors.text.greyLight,
                      borderRadius: "0 0 10px 10px",
                      "& .MuiAutocomplete-noOptions": {
                        background: theme.colors.background.formInput,
                        color: theme.colors.text.greyLight,
                      },
                    }}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontFamily={theme.fonts.livvic}
                  sx={{
                    fontWeight: theme.fonts.fontWeightBold,
                    fontSize: "20px",
                    marginBottom: "1%",
                    "@media (max-width: 480px)": {
                      fontSize: "15px",
                    },
                  }}
                >
                  About Us
                  <span
                    style={{
                      fontFamily: theme.fonts.livvic,
                      fontSize: "16px",
                      fontWeight: theme.fonts.fontWeightRegular,
                      color: theme.colors.text.greyDark,
                    }}
                  >
                    {" "}
                    (Minimum 50 characters)
                  </span>
                </Typography>
                <Typography
                  variant="caption"
                  fontSize="20px"
                  color={
                    formData?.about.length === maxLength
                      ? theme.colors.text.red
                      : theme.colors.text.greyDark
                  }
                >
                  {formData?.about.length}/{maxLength}
                </Typography>
              </Box>
              <TextField
                name="aboutUs"
                placeholder="Write about the brand"
                multiline
                rows={3}
                inputProps={{ maxLength }}
                fullWidth
                // autoComplete="off"
                value={formData?.about || ""}
                onChange={(e) =>
                  handleSelectionsChange("about", e.target.value)
                }
                InputProps={{
                  sx: {
                    border: `1px solid ${theme.colors.common.white}`,
                    background: theme.colors.background.formInput,
                    color: theme.colors.common.white,
                    borderRadius: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    color: theme.colors.text.greyLight,
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused": {
                      fieldset: {
                        borderColor: theme.colors.common.white,
                      },
                    },
                  },
                  "& input, & textarea, & select": {
                    WebkitTextFillColor: "#FFFFFF",
                    WebkitBoxShadow: "0 0 0px 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  },
                }}
              />
            </Grid>

            {/* -------------------------Submit Button------------------------ */}

            <Grid item xs={12} textAlign="center">
              <Button
                fullWidth
                sx={{
                  background: theme.colors.background.registerButton,
                  color: "white",
                  height: "50px",
                  maxWidth: "400px",
                  fontSize: "22px",
                  fontFamily: theme.fonts.livvic,
                  fontWeight: theme.fonts.fontWeightMedium,
                  borderRadius: "10px",
                  mt: 3,
                  py: 3.5,
                  "&.MuiButton-root.Mui-disabled": {
                    background: "rgba(255,0,0,0.6)",
                    color: "white",
                  },
                  "&:hover": {
                    background: theme.colors.divider.red,
                  },
                }}
                disabled={loading}
                onClick={handleSubmit}
              >
                {loading ? (
                  <CircularProgress sx={{ color: "gray" }} />
                ) : (
                  "SUBMIT"
                )}
              </Button>
              <Dialog
                open={successRegModalOpen}
                //onClose={handleOtpModalClose}
                PaperProps={{
                  sx: {
                    width: "80vw",
                    background: theme.colors.common.black,
                    color: theme.colors.common.white,
                    borderRadius: theme.fonts.smallBorderRadius,
                  },
                }}
                sx={{
                  backdropFilter: "blur(3px)",
                  backgroundColor: "rgb(255,255,255,0.3)",
                }}
              >
                <BrandRegSuccessfulModal />
              </Dialog>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PublicProfileBrand;
